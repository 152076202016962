
import { Options, prop, Vue } from "vue-class-component";
import MainProducts from "./components/main-products.vue";
import MoreProducts from "./components/more-products.vue";
import LessProducts from "./components/less-products.vue";
import HighLightProducts from "./components/higlight-products.vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { SubmitPosterProductApiRequest } from "@/data/payload/api/BookingRequest";
import {
  PosterProduct,
  PayloadPosterProduct,
  FooterColorPosterProduct,
  ParameterActive,
  ComodityProductPoster,
  PiecesPosterProduct
} from "@/domain/entities/Booking";
import {
  productStyle,
  productHighlight,
  allProductsDefault,
  allProductsWithoutOnePacks,
  productSameday
} from "./data";
import { AccountController } from "@/app/ui/controllers/AccountController";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";
import { AccountEntities } from "@/domain/entities/Account";

class Props {
  productType = prop<string>({
    default: "",
    type: String,
  });
  isDisabledProduct = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isOnePack = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isJumboPack = prop<boolean>({
    default: false,
    type: Boolean
  });
  isSameday = prop<boolean>({
    default: false,
    type: Boolean
  });
  isShipmentCod = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isEdit = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isFormForeign = prop<boolean>({
    default: false,
    type: Boolean,
  });
  version = prop<string>({
    default: "v1",
    type: String
  });
  isEmbargo = prop<any>({
    default: false,
    type: Boolean
  });
  hideKgs = prop<boolean>({
    default: false,
    type:Boolean
  });
  bookingType = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  emits: ["choseeProduct", "setErrorTriggered"],
  components: {
    MainProducts,
    MoreProducts,
    LessProducts,
    HighLightProducts,
  },
})
export default class ProductPoster extends Vue.with(Props) {
  origin = "";
  destination = "";
  shipmentId = "";
  isVisiblePrice = false;
  firstRender = true;
  loading = false;
  errorType = "";
  errorCause = "";

  errorTariff = {
    error: false,
    target: "Shipping Surcharge and Publish Rate price for selected route is not available",
    message: "Rute yang dipilih belum memiliki tarif pengiriman"
  }
  originalData: PosterProduct[] = []

  mounted() {
    if (this.version === 'v2') {
      this.expandProduct();
    }
  }

  async getPosterProduct(
    shipmentId: string,
    origin: string,
    destination: string,
    isVisiblePrice: boolean,
    accountRefId: number,
    accountType: string,
    goodsPrice = "",
    commodity: any = "",
    pieces: any = [],
    isCod = false,
    isDfod = false
  ) {
    this.currentIndexProduct = 0;
    this.currentProduct = "";
    this.origin = origin;
    this.destination = destination;
    this.shipmentId = shipmentId;
    this.isVisiblePrice = isVisiblePrice;
    this.showButtonLessProduct = false;
    this.isExpandProduct = false;
    this.firstRender = false;
    this.errorType = "";
    this.errorCause = "";
    this.errorTariff.error = false;
    this.loading = true;
    this.$emit("setErrorTriggered", false);

    const goodsPriceNumber = goodsPrice ? +goodsPrice?.replace(/[.]/g, "")?.replaceAll(",", ".") : 0;
    const defaultCODAmount = isCod ? 1 : 0;
    const codAmount =
      goodsPriceNumber == 0 ? defaultCODAmount : goodsPriceNumber;

    const isShipmentCod =  /^ARA|^ARB/.test(this.shipmentId);
    await BookingController.postToGetPosterProduct(
      new SubmitPosterProductApiRequest({
        payload: new PayloadPosterProduct({
          accountRefId: accountRefId,
          accountType: accountType,
          originId: origin,
          destinationId: destination,
          promoAppliedTo: isShipmentCod ? "shipment" : "",
          isDisablePromo: !isShipmentCod,
          shipmentPrefix: isShipmentCod ? shipmentId.slice(0, 3) : "",
          goodsPrice: goodsPriceNumber,
          commodityId: typeof commodity === "object" ? +commodity?.id : 499,
          commodity: typeof commodity === "object" ? { data: new ComodityProductPoster({
            commodityId: +commodity?.id,
            commodityName: commodity?.name,
            commodityCode: commodity?.code
          })} : { data: new ComodityProductPoster() },
          pieces: pieces ? pieces : [new PiecesPosterProduct()],
          codAmount: codAmount,
          isCod: isCod,
          isDfod: isDfod
        }),
      })
    ).then((data: PosterProduct[]) => {
        this.mappingProduct(data, isVisiblePrice);
        this.originalData = data;
        const everyEmbargo = this.originalData.every(key => key.isEmbargo);
        BookingController.setProductHaveEmbargo(everyEmbargo);
    }).catch((error: any) => {
      this.resetAllProduct();
      this.$emit("setErrorTriggered", true);
      const errorTariff = (error?.response?.data?.message?.en?.toLowerCase() || '') === this.errorTariff.target.toLowerCase()
      if (errorTariff) {
        this.errorTariff.error = true;
      }

      if (error?.response?.data.code >= 400 ) {
        this.errorType = "errorData";
        this.errorCause = "Terjadi gangguan sistem untuk kota tujuan Anda karena alasan tertentu"
      }

      if (error?.response?.data.code >= 500 || error.response === undefined) {
        this.errorType = "errorServer";
        this.errorCause = "Jenis Pengiriman Gagal Ditampilkan"
      }
    }).finally(() => {
      this.loading = false;
      if (this.version === 'v2')this.expandProduct();
    })
  }

  products: PosterProduct[] = this.isOnePack ? this.productOnePackWithJumbopack : this.productNonOnePackWithJumboPack;
  productHighlight: PosterProduct = productHighlight;
  productSameday: PosterProduct = productSameday;
  mappingProduct(productPosters: PosterProduct[], isVisiblePrice: boolean) {
    let productOrders: string[] = []
    if (this.isFormForeign) {
      productOrders = ["interpack"];
    } else {
      productOrders = [
        "onepack",
        "regpack",
        "jagopack",
        "bigpack",
        "interpack",
        "otopack150",
        "otopack250",
        "vippack"
      ];
      if (this.isJumboPack && this.allowedJumbopackAccess) {
        productOrders.splice(3, 0, "jumbopack")
      }
      if (!this.isOnePack) {
        productOrders.splice(0, 1);
      }
    } 

    const productOrdersHiglight = ["sameday", "bosspack"];
    this.products = this.loopProducts(productOrders, productPosters, isVisiblePrice);
    this.productHighlight = this.loopProducts(productOrdersHiglight, productPosters, isVisiblePrice)[1];
    this.productSameday = this.loopProducts(productOrdersHiglight, productPosters, isVisiblePrice)[0];
    if (!this.isShipmentCod) {
      if (this.version === 'v2' && !this.productType) {
        this.autoSelectProduct();
      } else {
        const ceckBosspack = productPosters.find((item: PosterProduct) => item.name === "bosspack");
        const ceckSameday = productPosters.find((item: PosterProduct) => item.name === "sameday");
        if (ceckBosspack && !this.productType) {
          this.updateActiveHighLightProduct();
        }
        if (ceckSameday && !this.productType) {
          this.updateActiveHighLightProductSameday();
        }
        const isProductHighlights = productOrdersHiglight.includes(this.productType);
        if (isProductHighlights) {
          this.productHighlight.statusSelect = true;
          this.productSameday.statusSelect = this.isSameday;
        }
      }
    }
  }

  autoSelectProduct() {
    const data = [this.productHighlight, ...this.products].filter(
      (key) => key.statusActive
    );
    if (data.length <= 0) {
      return;
    }
    if (data[0].name.toLowerCase() === "bosspack") {
      this.updateActiveHighLightProduct();
    } else {
      const findIndex = this.products.findIndex((key) => key.name === data[0].name)
      if (findIndex > -1) this.setActiveMainProduct(findIndex, data[0].name);
    }
    BookingController.setProductName(data[0].name.toUpperCase());
  }

  loopProducts(productOrders: string[], productPosters: PosterProduct[], isVisiblePrice: boolean) {
    const data = [];
    for (const product of productOrders) {
      const productDetail = this.convertDetail(productPosters, product, isVisiblePrice);
      const checkProductAvailable = productPosters.find((item: PosterProduct) => item.name === product);
      const productInactive =  !this.checkProductUndefined(checkProductAvailable);
      const newData = new PosterProduct({
        name: product,
        icon: productStyle[product].icon,
        motto: productStyle[product].motto,
        price: productDetail.price,
        sla: productDetail.sla,
        color: productInactive 
          ? this.convertColorInactive(product).color 
          : productStyle[product].color,
        footer: new FooterColorPosterProduct({
          firstColor: productStyle[product].footer.firstColor,
          secondColor: productInactive
            ? this.convertColorInactive(product).footer
            : productStyle[product].footer.secondColor,
        }),
        discountAgentPrecentage: productDetail.discountAgentPrecentageFinal,
        discountAgentNominal: productDetail.discountAgentNominal,
        statusCard: !productInactive || this.cardActive(product),
        statusSelect: this.statusSelect(product, !productInactive),
        statusActive: !productInactive,
        isEmbargo: productDetail.isEmbargo
      });

      data.push(newData);
    }
    return data;
  }

  statusSelect(product: string, statusActive: boolean) {
    if (this.isActiveInterpackProduct(product, statusActive) && this.isFormForeign) {
      this.$emit("choseeProduct", {
        status: "ACTIVE",
        name: "INTERPACK"
      });
      
      return true;
    } else {
      return product === this.productType;
    }
  }

  isActiveInterpackProduct(product: string, statusActive: boolean) {
    return product === "interpack" && statusActive
  }

  get isNonForeign() {
    if (this.isEdit && this.isFormForeign) {
      return false;
    } else if(this.isEdit && !this.isFormForeign) {
      return true;
    } else {
      return !AccountController.accountData.accountIsForeign;
    }
    
  }

  get accountData(): AccountEntities {
    return AccountController.accountData;
  }
  get partnerIsAllowBookVippack(): boolean {
    return this.isEdit
      ? this.productType === "vippack"
      : this.accountData.account_type_detail?.partnerIsAllowBookVippack &&
          this.bookingType === "manual" &&
          this.accountData.account_type === "partner";
  }
  availableProducts(data: PosterProduct[]): PosterProduct[] {
    return data.filter(
      (item: PosterProduct) =>
        this.partnerIsAllowBookVippack || item.name !== "vippack"
    );
  }
  get productOnePackWithJumbopack() {
    const newProductFilter = this.isNonForeign ? allProductsDefault : allProductsDefault.filter((item: PosterProduct) => item.name === 'interpack')
    const newProducts = this.isJumboPack ? newProductFilter : newProductFilter.filter((item: PosterProduct) => item.name !== 'jumbopack')
    return this.availableProducts(newProducts);
  }

  get productNonOnePackWithJumboPack() {
    const newProductFilter = this.isNonForeign ? allProductsWithoutOnePacks : allProductsWithoutOnePacks.filter((item: PosterProduct) => item.name === 'interpack')
    const newProducts = this.isJumboPack ? newProductFilter : newProductFilter.filter((item: PosterProduct) => item.name !== 'jumbopack')
    return this.availableProducts(newProducts);
  }

  resetAllProduct() {
    this.firstRender = true;
    this.errorType = "";
    this.errorCause = "";
    this.errorTariff.error = false;
    const products = this.isOnePack ? this.productOnePackWithJumbopack : this.productNonOnePackWithJumboPack;
    this.products = products?.map((item: PosterProduct) => {
      return new PosterProduct({
        name: item.name,
        icon: item.icon,
        motto: item.motto,
        price: "Rp-",
        sla: "-",
        color: "#a7a8aa",
        footer: {
            firstColor: "#ffffff",
            secondColor: "#d4d5d8",
        },
        discountAgentPrecentage: "-",
        discountAgentNominal: "-",
        statusCard: false,
        statusSelect: false,
        statusActive: false
      })
    }) || [];

    this.productHighlight = new PosterProduct({
      name: "BOSSPACK",
      icon: "bosspack",
      motto: "Pengiriman Prioritas",
      price: "Rp-",
      sla: "-",
      color: "#a7a8aa",
      footer: {
          firstColor: "#ffffff",
          secondColor: "#a7a8aa",
      },
      discountAgentPrecentage: "-",
      discountAgentNominal: "-",
      statusCard: false,
      statusSelect: false,
      statusActive: false
    });

    this.productSameday = new PosterProduct({
      name: "SAMEDAY",
      icon: "sameday",
      motto: "Paket sampai di hari<br> yang sama",
      price: "Rp-",
      sla: "-",
      color: "#a7a8aa",
      footer: {
          firstColor: "#ffffff",
          secondColor: "#a7a8aa",
      },
      discountAgentPrecentage: "-",
      discountAgentNominal: "-",
      statusCard: false,
      statusSelect: false,
      statusActive: false
    });
  }

  setActiveProductDisabled(productType = "") {
    const product = productType === 'jumbopackh2h' ? 'jumbopack' : productType ? productType : this.productType
    this.resetAllProduct()
    const checkMainProductIndex = this.products.findIndex((item: PosterProduct) => item.name === product);
    if (checkMainProductIndex > -1) this.setActiveMainProduct(checkMainProductIndex, product);
    if (product === "bosspack") this.setActiveHighlightProduct(product);
    if (product === "sameday") this.setActiveHighlightProductSameday(product);
  }

  setActiveHighlightProductSameday(productType: string) {
    this.firstRender = false;
    this.productSameday.statusSelect = true;
    this.productSameday.statusCard = true;
    this.productSameday.statusActive = true;
    this.productSameday.color = productStyle[productType].color;
    this.productSameday.footer.firstColor = productStyle[productType].footer.firstColor;
    this.productSameday.footer.secondColor = productStyle[productType].footer.secondColor;
  }

  disableProductShipmentCod = false;
  isBosspackShipmentCod = true;
  setProductShipmentCod(productType: string) {
    const checkMainProductIndex = this.products.findIndex((item: PosterProduct) => item.name === productType && item.statusActive);
    const checkBosspack = this.productHighlight.name === productType && this.productHighlight.statusActive;

    if (checkMainProductIndex > -1 || checkBosspack) {
      this.disableProductShipmentCod = true;
      this.setProductShipmentCodExist(checkBosspack, checkMainProductIndex, productType);
    } else {
      this.disableProductShipmentCod = false;
      this.mappingProduct(this.originalData, this.isVisiblePrice);
    }
  }

  setProductShipmentCodExist(checkBosspack: boolean, checkMainProductIndex: number, productType: string) {
    if (checkBosspack) {
      this.setActiveHighlightProduct(productType);
    } else {
      this.setActiveMainProduct(checkMainProductIndex, productType);
    }

    this.$emit("choseeProduct", {
      status: "ACTIVE",
      name: productType.toUpperCase()
    });
  }

  setActiveMainProduct(index: number, productType: string) {
    this.products[index].statusSelect = true;
    this.products[index].statusCard = true;
    this.products[index].statusActive = true;
    this.products[index].color = productStyle[productType].color;
    this.products[index].footer.firstColor = productStyle[productType].footer.firstColor;
    this.products[index].footer.secondColor = productStyle[productType].footer.secondColor;
  }

  setActiveHighlightProduct(productType: string) {
    this.firstRender = false;
    this.productHighlight.statusSelect = true;
    this.productHighlight.statusCard = true;
    this.productHighlight.statusActive = true;
    this.productHighlight.color = productStyle[productType].color;
    this.productHighlight.footer.firstColor = productStyle[productType].footer.firstColor;
    this.productHighlight.footer.secondColor = productStyle[productType].footer.secondColor;
  }

  convertColorInactive(product: string) {
    const productColor: any = {
      bosspack:{
        color:  "#a7a8aa",
        footer:  "#a7a8aa"
      }
    }
    return productColor[product] || {
      color:  "#a7a8aa",
      footer:  "#d4d5d8"
    }
  }

  checkProductUndefined(checkProductAvailable: any) {
    return !!checkProductAvailable
  }

  cardActive(product: string) {
    return product === this.productType;
  }

  convertDetail(productPosters: PosterProduct[], product: string, isVisiblePrice: boolean) {
    const productDetail = isVisiblePrice ? productPosters.find(
      (item: PosterProduct) => item.name === product
    ) : null;

    const discountAgentPercentage = product !== "bigpack" ? `${productDetail?.discountAgentPrecentage}%` : "";
    const units: any = {
      "bigpack": "/10kg awal",
      "onepack": "/kg",
      "regpack": "/kg",
      "jagopack": "/kg",
      "interpack": "/kg",
      "sameday": "/kg",
      "bosspack": "/kg",
      "jumbopack": "/30kg awal"
    }

    const suffixPrice = units[product] || "";
    return {
      price: productDetail ? `${this.formatCurrency(Number(productDetail.price))}${suffixPrice}` : this.defaultPriceLabel,
      discountAgentPrecentageFinal: productDetail ? `${discountAgentPercentage}` : "-",
      sla: productDetail ? (`${productDetail.sla} ${this.defaultSLALabel}`).toLowerCase() : "-",
      discountAgentNominal: productDetail ? `${this.formatCurrency(Number(productDetail?.discountAgentNominal))}${suffixPrice}` : "-",
      isEmbargo : productDetail ? productDetail.isEmbargo : false
    }
  }

  get defaultPriceLabel() {
    return this.isFormForeign ? "RM-" : "Rp-";
  }

  get defaultSLALabel() {
    return this.isFormForeign ? "days" : "kerja";
  }

  formatCurrency(value: any) {
    return this.isFormForeign
      ? formatPriceRM(Number(value))
      : formatPriceRP(Number(value));
  }

  isExpandProduct = false;
  expandProduct() {
    this.isExpandProduct = true;
    this.showButtonLessProduct = true;
  }

  showButtonLessProduct = false;
  unExpandProduct() {
    this.isExpandProduct = false;
    this.showButtonLessProduct = false;
  }

  currentIndexProduct = 0;
  currentProduct = "";
  updateProductActive(parameter: ParameterActive) {
    this.productHighlight.statusSelect = false;
    this.productSameday.statusSelect = false;
    this.products[this.currentIndexProduct].statusSelect = false;
    this.currentIndexProduct = parameter.index;
    this.currentProduct = parameter.product;
    this.products[parameter.index].statusSelect = true;

    parameter.product === "interpack" ? this.pickInterpack() : this.pickNonInterpack();
    this.$emit("choseeProduct", {
      status: "ACTIVE",
      name: this.currentProduct.toUpperCase()
    });
  }

  pickInterpack() {
    this.products.forEach((item: PosterProduct, index: number) => {
      if (item.name !== "interpack") {
        if (item.statusActive) {
          this.products[index].color = "#a7a8aa";
          this.products[index].footer.secondColor = "#d4d5d8";
          this.products[index].statusCard = false;
        }
      } else {
        if (item.statusActive) {
          this.products[index].color = "#d64f71";
          this.products[index].footer.secondColor = "#f4dfe7";
          this.products[index].statusCard = true;
        }
      }
    })

    if (this.productHighlight.statusActive || this.productSameday.statusActive) {
      this.productHighlight.color = "#a7a8aa";
      this.productHighlight.footer.secondColor = "#a7a8aa";
      this.productHighlight.statusCard = false;
      this.productSameday.color = "#a7a8aa";
      this.productSameday.footer.secondColor = "#a7a8aa";
      this.productSameday.statusCard = false;
    }
  }

  pickNonInterpack() {
    this.products.forEach((item: PosterProduct, index: number) => {
      if (item.name === "interpack") {
        if (item.statusActive) {
          this.products[index].color = "#a7a8aa";
          this.products[index].footer.secondColor = "#d4d5d8";
          this.products[index].statusCard = false;
        }
      } else {
        if (item.statusActive) {
          this.products[index].color = productStyle[item.name].color;
          this.products[index].footer.secondColor = productStyle[item.name].footer.secondColor;
          this.products[index].statusCard = true;
        }
      }
    })

    if (this.productHighlight.statusActive) {
      this.productHighlight.color = productStyle["bosspack"].color;
      this.productHighlight.footer.secondColor = productStyle["bosspack"].footer.secondColor;
      this.productHighlight.statusCard = true;
      this.productSameday.color = "#a7a8aa";
      this.productSameday.footer.secondColor = "#a7a8aa";
      this.productSameday.statusCard = false;
    }
  }

  updateActiveHighLightProduct() {
    this.currentProduct = "bosspack";
    this.products[this.currentIndexProduct].statusSelect = false;
    this.productHighlight.statusSelect = true;
    this.pickNonInterpack();
    this.$emit("choseeProduct", {
      status: "ACTIVE",
      name: this.currentProduct.toUpperCase()
    });
  }

  updateActiveHighLightProductSameday() {
    this.currentProduct = "sameday";
    this.products[this.currentIndexProduct].statusSelect = false;
    this.productSameday.statusSelect = true;
    this.pickNonInterpack();
    this.$emit("choseeProduct", {
      status: "ACTIVE",
      name: this.currentProduct.toUpperCase()
    });
  }

  get allowedJumbopackAccess() {
    return BookingController.dataProfile.isInternalAccount || BookingController.dataProfile.isPartnerPcu || BookingController.dataProfile.isAllClient;
  }
}
