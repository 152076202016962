
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import Checkbox from "primevue/checkbox";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
import formattedNPWP from "@/app/infrastructures/misc/common-library/FormattedNPWP";
import prefixAlgoEstimationShow from "@/app/infrastructures/misc/common-library/PrefixAlgoEstimationShow";
import prefixAlgoForSameCustomer from "@/app/infrastructures/misc/common-library/PrefixAlgoForSameCustomer";
import isUrlUpdateVersion from "@/app/infrastructures/misc/common-library/IsUrlUpdateVersion";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import DetailUncalculation from "./payment-detail-footer/uncalculation.vue";
import DetailCalculation from "./payment-detail-footer/calculation.vue";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { maxLengthWeight } from "../component/modules";
import {
  maxKg,
  SHIPMENT_ID_FAVOURITE,
  codePhoneCountry,
  totalGrossWeightSikat,
  totalGrossWeightNonSikat,
  clientMapperSikat,
  promoPrefix,
  MAX_WEIGHT_SHIPMENT,
  validateCHWeight,
  prefixDisableDimension,
  volumeWeightForProduct,
  prefixDisableDimensionC1,
  gapValidationCHWeightTokped
} from "@/app/ui/views/shipment/booking/booking-utils";
import ErrorMessageBooking from "../component/error-message/destination-vendor-ninja.vue";
import LpInputSearch from "@/app/ui/components/lp-input-search/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import SelectPhoneCountry from "../component/select-phone-country.vue";
import { WeightDimension } from "@/domain/entities/Booking";
import { ProductConfigurationRuleList } from "@/domain/entities/Product";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { ProductConfigurationRuleRequestParams } from "@/data/payload/api/ProductApiRequest";
import WarningSlaTransportation from "@/app/ui/views/shipment/booking/component/error-message/warning-sla-transportation.vue";
import BannerProductType from "@/app/ui/views/shipment/booking/component/banner-product-type.vue";
import ProductPoster from "../component/product-poster/index.vue";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";
import RadioButton from "primevue/radiobutton";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { CodConfigurationController } from "@/app/ui/controllers/CodConfigurationController";
import { ApiRequestList, UploadMultiple } from "@/domain/entities/MainApp";
import formatPriceWithoutCurrency from "@/app/infrastructures/misc/common-library/FormatPriceWithoutCurrency";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
import InformationCodRetail from "@/app/ui/views/shipment/booking/component/error-message/warning-cod-retail.vue";
import Woodpacking from "../component/woodpacking/index.vue";
import SectionUploadPhotoForWeightAdjusment from "@/app/ui/views/shipment/booking/component/section-upload-photo-for-weight-adjusment/index.vue";
import { formatPrice, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import {
  DeleteHydraFileRequest,
  UploadHydraFileRequest
} from "@/data/payload/api/BookingRequest";
import { flags } from "@/feature-flags";
import { HeavyWeightSurchargeController } from "@/app/ui/controllers/HeavyWeightSurchargeController";
import { CheckEmbargoApiRequest } from "@/data/payload/api/EmbargoApiRequest";
import { EmbargoController } from "@/app/ui/controllers/EmbargoController";

@Options({
  inheritAttrs: false,
  components: {
    WarningSlaTransportation,
    Title,
    Tooltip,
    InputDimension,
    Checkbox,
    DetailUncalculation,
    DetailCalculation,
    ErrorMessageBooking,
    LpInputSearch,
    OverlayPanel,
    SelectPhoneCountry,
    BannerProductType,
    ProductPoster,
    RadioButton,
    InformationCodRetail,
    Woodpacking,
    SectionUploadPhotoForWeightAdjusment
  },
  watch: {
    indexElement() {
      this.shipmentNoRefExt = this.isCourierRecommendation 
        ? this.shipmentList[this.indexElement].dataRaw?.ticket_code
        : this.shipmentList[this.indexElement].shipmentBookingId.startsWith("TSLP") 
        ? this.shipmentList[this.indexElement].shipmentBookingId
        : this.shipmentList[this.indexElement].STT?.sttNoRefExternal || "";
      this.fetchDetailDistrictDestination();
      this.lists();
    }
  }
})
export default class extends Vue {
  get maxAddressLength() {
    return FlagsMedusa.config_max_address.getValue();
  }

  props: any = this.$props;

  setModalDetailShipment(value: boolean) {
    ShipmentBookingController.setShowModalDetailBookingShipment(value);
  }

  formatPrice(value: number) {
    return formatPrice(value);
  }

  error = {
    sttManual: false,
    productName: false,
    taxNumber: false,
    phoneSender: false,
    phoneCodeSender: false,
    phoneCharMinimalSender: false,
    phoneCharMinimalReceiver: false,
    phoneReceiver: false,
    phoneCodeReceiver: false,
    estimationPrice: false,
    grossWeight: false,
    maxPerPieces: false,
    maxTotalGrossWeight: false,
    maxTotalVolumeWeight: false,
    product: false,
    insurance: false
  };
  shipmentNoRefExt = this.isCourierRecommendation
    ? this.shipmentList[this.indexElement].dataRaw?.ticket_code
    : this.shipmentList[this.indexElement].shipmentBookingId.startsWith("TSLP") 
    ? this.shipmentList[this.indexElement].shipmentBookingId
    : this.shipmentList[this.indexElement].STT?.sttNoRefExternal || "";
  isSaveSender = false;
  isSaveReceiver = false;
  get detailShipment() {
    return {
      shipmentAlgoId: this.shipmentList[this.indexElement].shipmentAlgoId,
      shipmentProduct: this.shipmentList[this.indexElement].shipmentProduct,
      shipmentNoRefExt: this.isCourierRecommendation
        ? this.shipmentList[this.indexElement].dataRaw?.ticket_code
        : this.shipmentList[this.indexElement].STT?.sttNoRefExternal || "",
      shipmentOriginCity: this.shipmentList[this.indexElement]
        .shipmentOriginCity,
      shipmentOriginDistrict: this.shipmentList[this.indexElement]
        .shipmentOriginDistrict,
      shipmentDestinationCity: this.shipmentList[this.indexElement]
        .shipmentDestinationCity,
      shipmentDestinationDistrict: this.shipmentList[this.indexElement]
        .shipmentDestinationDistrict,
      shipmentSenderName: this.shipmentList[this.indexElement]
        .shipmentSenderName,
      shipmentSenderAddress: this.shipmentList[this.indexElement]
        .shipmentSenderAddress,
      shipmentSenderPhone: this.shipmentList[this.indexElement]
        .shipmentSenderPhone,
      shipmentRecipientName: this.shipmentList[this.indexElement]
        .shipmentRecipientName,
      shipmentRecipientAddress: this.shipmentList[this.indexElement]
        .shipmentRecipientAddress,
      shipmentRecipientPhone: this.shipmentList[this.indexElement]
        .shipmentRecipientPhone,
      shipmentCommodityCode: this.shipmentList[this.indexElement]
        .shipmentCommodityCode,
      shipmentCommodityName: this.shipmentList[this.indexElement]
        .shipmentCommodityName,
      shipmentGoodsStatus: this.shipmentList[this.indexElement]
        .shipmentGoodsStatus,
      shipmentInsurance: this.shipmentList[this.indexElement].shipmentInsurance,
      shipmentGoodsPriceEstimation: this.shipmentList[this.indexElement]
        .shipmentGoodsPriceEstimation,
      shipmentNpwpNumber: this.shipmentList[this.indexElement]
        .shipmentNpwpNumber,
      shipmentGoodDetail: this.shipmentList[this.indexElement]
        .shipmentGoodDetail,
      shipmentGoodDetailKeeped: this.shipmentList[this.indexElement]
        .shipmentGoodDetailKeeped,
      shipmentIsCod: this.shipmentList[this.indexElement].shipmentIsCod,
      shipmentIsWoodpacking: false,
      shipmentPackageid: this.shipmentList[this.indexElement].shipmentPacketId,
      shipmentChargeableWeight: this.shipmentList[this.indexElement]
        .shipmentChargeableWeight,
      customerName: this.shipmentList[this.indexElement].customerName,
      isAdjustable: this.shipmentList[this.indexElement].isAdjustable,
      maxAdjustable: this.shipmentList[this.indexElement].maxAdjustable,
      STT: this.shipmentList[this.indexElement].STT,
      isSaveSender: false,
      isSaveReceiver: false,
      taxNumber: "",
      destination: this.destination,
      shipmentCodAmount: this.shipmentList[this.indexElement].codAmount,
      shipmentSttClientId: this.shipmentList[this.indexElement]
        .shipmentSttClientId
    };
  }

  get detail() {
    return ShipmentBookingController.detailBookingShipment;
  }

  get shipmentList() {
    const data = BookingController.shipmentIdList.data;
    const collator = new Intl.Collator("en", {
      numeric: true,
      sensitivity: "base"
    });

    return [
      ...data.filter(item => !item.STT),
      ...data
        .filter(item => !!item.STT)
        .sort((a, b) =>
          collator.compare(a.STT ? a.STT.sttNo : "", b.STT ? b.STT.sttNo : "")
        )
        .reverse()
    ];
  }

  get isPrintAble() {
    return this.detailShipment.STT !== null;
  }
  get isShipmentMp(): boolean {
    const flags = FlagsPermissionBooking.permission_shipment_booking_mp.getValue();
    if (flags) {
      return !!new RegExp(
        FlagsPermissionBooking.permission_shipment_booking_mp.getValue()
      ).exec(this.detailShipment.shipmentAlgoId);
    }
    return false;
  }

  get isShipmentCASameday() {
    return (
      BookingController.isSameDayProduct &&
      this.detailShipment.shipmentProduct?.toLowerCase() === "sameday"
    );
  }

  get isEnablePiecesBooking() {
    return !!new RegExp(
      FlagsPermissionBooking.permission_pieces_booking_shipment.getValue()
    ).exec(this.detailShipment.shipmentAlgoId);
  }

  get configDisableC1() {
    return (
      !prefixDisableDimensionC1(
        String(AccountController.accountData.account_id)
      ) && this.detailShipment.shipmentAlgoId.match(/^C1|^C2/gi)
    );
  }

  // Tooltip
  isOpenTooltipStatusGoods = false;
  isOpenTooltipShipmentID = false;
  isOpenTooltipInsurance = false;
  isOpenTooltipTax = false;
  isHoverDelete = false;
  isOpenTooltipWeight = false;
  isOpenTooltipPiecePerPack = false;
  isOpenTooltipNextCommodity = false;

  cacheIndex = 0;
  origins = [];
  destinations = [];
  commodity = "";
  sender = "";
  receiver = "";
  commodityName = "";
  isOpenCommodity = false;
  checkedNumberSender = false;
  checkedNumberReceiver = false;
  isStatusGoods = false;
  isOpenSelectInsurance = false;
  tempIndexToRemove = -1;
  isEditTerminated = false;
  newPath = "";
  answer = false;
  listKGs: any = [];
  listStatusGoods = [
    { name: "Pilih status pengiriman yang akan dikirim", value: "" },
    { name: "Ecommerce", value: "ecommerce" },
    { name: "Produk lokal / Batam", value: "batam" },
    { name: "Eks luar Batam / transit", value: "transit" },
    { name: "Salah kirim / return", value: "return" },
    { name: "Personal effect", value: "personal-effect" }
  ];

  firstCHWeight = 0;
  async mounted() {
    await this.fetchProductConfigurableRule();
    await AccountController.checkAccountLocation();
    await this.getMinimumHeavyWeight();
    this.fetchDetailDistrictDestination();
    this.fetchListConfigurationCOD();
    this.getCommodityList();
    this.getCustomerList();
    this.fetchDestinationCity("");
    ShipmentBookingController.setShowModalShipmentBooking(false);
    BookingController.setShowModalManualBooking(false);
    BookingController.setDefaultManualBooking();
    ShipmentBookingController.setError(false);
    BookingController.setEmptyDestination("");
    BookingController.setIncrementManualBooking();
    this.lists();
    this.filterProductsDelivery("");
    this.getInsurancesTypeDelivery();
    this.handleErrorDestinationCity();
    this.mountedProductUpsellingDefault();
    this.setFirstLimitCHWeight();

    this.codStatus();
    this.dfodStatus();
    this.totalChargeableWeightKeeped = this.totalChargeableWeight;
  }

  get errorMaxChWeight() {
    const chWeight = this.isCampaign
      ? this.totalCampaignChargeableWeight
      : this.totalChargeableWeight;
    return gapValidationCHWeightTokped(
      this.totalChargeableWeightKeeped,
      chWeight
    );
  }

  setFirstLimitCHWeight() {
    this.firstCHWeight = this.isCampaign
      ? this.totalCampaignChargeableWeight
      : this.totalChargeableWeight;
  }

  setEditTerminated(value: boolean) {
    this.isEditTerminated = value;
  }

  get handleDisabledInput() {
    const prefix = this.detailShipment.shipmentAlgoId;
    return !(
      prefix.startsWith("AP") ||
      prefix.startsWith("AS") ||
      prefix.startsWith("TKP")
    );
  }

  get enabledCommodityT1B1B2(){
    const prefix = this.detailShipment.shipmentAlgoId;
    return (prefix.startsWith("T1") || prefix.startsWith("B1") || prefix.startsWith("B2"));
  }

  get isErrorSpesificRate() {
    return ShipmentBookingController.errorSpesificByRate;
  }

  get isErrorAdd() {
    return ShipmentBookingController.isError;
  }

  get titleErrorspesification() {
    return ShipmentBookingController.titleErrorspesification;
  }
  // address type types
  get listAddressType() {
    const defaultChoices: any = [
      {
        name: this.$t("bookingShipment.column.office"),
        value: "office"
      },
      {
        name: this.$t("bookingShipment.column.home"),
        value: "home"
      }
    ];

    return defaultChoices;
  }

  isErrorSelectAddressType = false;
  onSelectAddressType(name: string, value: string) {
    this.shipmentList[this.indexElement].sttRecipientAddressType = value;
    if (!value) {
      this.isErrorSelectAddressType = true;
    } else {
      this.isErrorSelectAddressType = false;
    }
  }

  lists() {
    if (!this.detailShipment.shipmentGoodDetail.length) {
      this.listKGsDetail();
      return;
    }
    this.listKGs = this.detailShipment.shipmentGoodDetail.map(
      (key: any, index: number) => {
        const volWeightSecondParam = this.convertDefaultWeight(key);
        const secondValue = volWeightSecondParam[1]
          ? "," + volWeightSecondParam[1].slice(0, 3)
          : "";

        return {
          grossWeight: key.shipment_goods_weight,
          dimension: this.dimensionList(key),
          volumeWeight: this.volumenWeightList(key),
          convertVolumeWeight:
            (
              ((key?.shipment_goods_length === 0
                ? 10
                : key?.shipment_goods_length) *
                (key.shipment_goods_width === 0
                  ? 10
                  : key.shipment_goods_width) *
                (key.shipment_goods_height === 0
                  ? 10
                  : key.shipment_goods_height)) /
              this.division
            )
              .toString()
              .replace(".", ",")
              .split(",")[0] + secondValue,
          errorMaxPerPieces: false,
          isDimensionDisabled:
            this.configDisableDimension &&
            key.shipment_goods_weight < MAX_WEIGHT_SHIPMENT,
          warningHwsSingle:
            this.minimumHeavyWeightSurcharge.minimumExist &&
            this.detailShipment.shipmentGoodDetail.length === 1 &&
            key.shipment_goods_weight >=
              this.minimumHeavyWeightSurcharge.heavyWeightMinimum,
          isErrorHws:
            this.minimumHeavyWeightSurcharge.minimumExist &&
            this.detailShipment.shipmentGoodDetail.length > 1 &&
            key.shipment_goods_weight >=
              this.minimumHeavyWeightSurcharge.heavyWeightMinimum
        };
      }
    );
  }

  get isMarketPlaceShipment() {
    const prefix = this.detailShipment.shipmentAlgoId;
    return (
      prefix.startsWith("B1") ||
      prefix.startsWith("B2") ||
      prefix.startsWith("T1") ||
      prefix.startsWith("TKLP")
    );
  }

  convertDefaultWeight(key: any) {
    return (
      ((key?.shipment_goods_length === 0 ? 10 : key?.shipment_goods_length) *
        (key.shipment_goods_width === 0 ? 10 : key.shipment_goods_width) *
        (key.shipment_goods_height === 0 ? 10 : key.shipment_goods_height)) /
      this.division
    )
      .toString()
      .replace(".", ",")
      .split(",");
  }

  listKGsDetail() {
    this.listKGs = [
      new WeightDimension({
        grossWeight: 0,
        dimension: {
          length: 10,
          width: 10,
          height: 10
        },
        isDimensionDisabled: this.configDisableDimension
      })
    ];
  }

  dimensionList(key: any) {
    return {
      length: key?.shipment_goods_length === 0 ? 10 : key?.shipment_goods_length,
      width: key.shipment_goods_width === 0 ? 10 : key.shipment_goods_width,
      height: key.shipment_goods_height === 0 ? 10 : key.shipment_goods_height
    };
  }

  volumenWeightList(key: any) {
    return (
      ((key?.shipment_goods_length === 0 ? 10 : key?.shipment_goods_length) *
        (key.shipment_goods_width === 0 ? 10 : key.shipment_goods_width) *
        (key.shipment_goods_height === 0 ? 10 : key.shipment_goods_height)) /
      this.division
    );
  }

  get disabledInput() {
    let result = false;
    const prefix = this.detailShipment.shipmentAlgoId.slice(0, 2);
    result =
      this.disableInputForMarketPlace(prefix) ||
      prefix === "AG" ||
      prefix === "AD" ||
      prefix === "DO" ||
      prefix === "AI" ||
      prefix === "AR" ||
      prefix === "AC";
    return result;
  }

  disableInputForMarketPlace(prefix: string) {
    return (
      prefix === "B1" || prefix === "B2" || prefix === "T1" || prefix === "TK"
    );
  }

  get estimastionColumnDisable() {
    const prefix = this.detailShipment.shipmentAlgoId.slice(0, 2);
    return prefix === "AC";
  }

  get index() {
    let ind = 0;
    this.shipmentList.forEach((key: any, index: number) => {
      if (key === this.detail) {
        ind = index;
        return ind;
      }
    });
    ShipmentBookingController.setIndexElement(ind);
    return ind;
  }

  get indexElement() {
    return ShipmentBookingController.indexElement;
  }

  get errorCause() {
    return ShipmentBookingController.isErrorCause;
  }

  get convertedArr() {
    return this.listKGs.map((key: any) => ({
      stt_piece_length: key.dimension.length,
      stt_piece_width: key.dimension.width,
      stt_piece_height: key.dimension.height,
      stt_piece_gross_weight: parseFloat(
        Number(key.grossWeight.toString().replace(",", ".")).toFixed(3)
      ),
      stt_piece_volume_weight: parseFloat(Number(key.volumeWeight).toFixed(3))
    }));
  }

  get totalChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (
      this.totalGrossWeight.includes(",") ||
      this.totalVolumeWeight.includes(",")
    ) {
      total = this.compareTotalGrossAndVolume(x, y);
    } else {
      total = `${x > y ? x : y}`;
    }
    return total;
  }

  get productConfigRuleActive() {
    const clientId = this.detailShipment.shipmentAlgoId.startsWith("TKP")
      ? this.detailShipment.shipmentSttClientId
      : clientMapperSikat(this.detailShipment.shipmentAlgoId);

    return this.productConfigurationRuleList.findProductConfigRule(
      this.detailShipment.shipmentProduct,
      clientId,
      false
    );
  }
  get productConfigRuleDefaultActive() {
    return this.productConfigurationRuleList.findProductConfigRuleDefault(
      this.shipmentList[this.indexElement].shipmentProduct
    );
  }
  get isCampaign() {
    // booking MP do not have promo sikat
    if (this.detailShipment.customerName.toLowerCase() !== "customer apps") {
      return false;
    }
    return this.productConfigRuleActive.isCampaign;
  }

  get isGrossWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x > y;
  }

  get isVolumeWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x < y;
  }

  get totalCampaignChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (this.totalGrossWeight || this.totalVolumeWeight) {
      total = totalGrossWeightSikat(
        this.isCampaign,
        x,
        y,
        this.totalGrossWeight,
        this.totalVolumeWeight
      );
    } else {
      total = totalGrossWeightNonSikat(this.isCampaign, x, y);
    }
    return total;
  }

  compareTotalGrossAndVolume(x: number, y: number) {
    let total = 0;
    if (x > y) {
      total = this.totalGrossMoreThanVolume(x, y);
    } else {
      total = this.volumeMoreThanTotalGross(x, y);
    }
    return total;
  }

  totalGrossMoreThanVolume(x: number, y: number) {
    let total = 0;
    const split = this.totalGrossWeight.split(",");
    let result = x - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));
    if (x < 1) {
      total = 1;
    } else {
      total =
        result >= this.productConfigRuleDefaultActive.parameter
          ? Math.ceil(x)
          : Math.floor(x);
    }

    return total;
  }

  volumeMoreThanTotalGross(x: number, y: number) {
    let total = 0;
    const split = this.totalVolumeWeight.split(",");
    let result = y - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));
    if (y < 1) {
      total = 1;
    } else {
      total =
        result >= this.productConfigRuleDefaultActive.parameter
          ? Math.ceil(y)
          : Math.floor(y);
    }

    return total;
  }

  get payload(): any {
    BookingController.checkTransportAndEstimateSlaBooking({
      originCity: this.origin.cityCode,
      destinationCity:
        this.destination.cityCode === undefined
          ? this.shipmentList[this.indexElement].shipmentDestinationCity.code
          : this.destination.cityCode,
      originDistrict: this.origin.code,
      destinationDistrict: this.destination.code,
      product: this.shipmentList[this.indexElement]?.shipmentProduct
    }).then(response => {
      this.estimateSla = response;
    });

    return {
      ...this.payloadSectionOne,
      ...this.payloadSectionTwo,
      ...this.payloadSectionThree
    };
  }

  get payloadSectionOne() {
    return {
      sttManual: !this.manualBooking.sttManual.id
        ? ""
        : this.manualBooking.sttManual.id,
      referenceExternal: this.shipmentNoRefExt,
      productName: this.shipmentList[this.indexElement].shipmentProduct,
      originCity: this.origin.cityCode,
      originDistrict: this.origin.code,
      destinationCity:
        this.destination.cityCode === undefined
          ? this.shipmentList[this.indexElement].shipmentDestinationCity.code
          : this.destination.cityCode,
      destinationDistrict: this.destination.code,
      phoneSender: `${this.shipmentList[
        this.indexElement
      ].shipmentSenderPhoneCode.substring(1)}${
        this.shipmentList[this.indexElement].shipmentSenderPhone
      }`,
      phoneReceiver: `${this.shipmentList[
        this.indexElement
      ].shipmentRecipientPhoneCode.substring(1)}${
        this.shipmentList[this.indexElement].shipmentRecipientPhone
      }`,
      receiver: this.shipmentList[this.indexElement].shipmentRecipientName,
      sender: this.shipmentList[this.indexElement].shipmentSenderName,
      addressReceiver: this.shipmentList[this.indexElement]
        .shipmentRecipientAddress,
      addressSender: this.shipmentList[this.indexElement].shipmentSenderAddress,
      isSaveSender: this.isSaveSender,
      isSaveReceiver: this.isSaveReceiver
    };
  }

  get payloadSectionTwo() {
    const commodityCode: any = this.shipmentList[this.indexElement]
      .shipmentCommodityName;
    return {
      commodityCode: this.payloadCommodityCode(commodityCode),
      commodityId:
        typeof commodityCode === "object"
          ? commodityCode.id
          : this.shipmentList[this.indexElement].shipmentCommodityId,
      insurance: this.shipmentList[this.indexElement].shipmentInsurance,
      statusGoods: this.shipmentList[this.indexElement].shipmentGoodsStatus,
      estimationPrice: Number(
        this.shipmentList[this.indexElement].shipmentGoodsPriceEstimation
          .toString()
          .replace(/[.]/g, "")
      ),
      taxNumber: this.shipmentList[this.indexElement].shipmentNpwpNumber,
      isWoodpack: this.shipmentList[this.indexElement].shipmentIsWoodpacking,
      isCOD: this.shipmentList[this.indexElement].shipmentIsDfod
        ? true
        : this.shipmentList[this.indexElement].shipmentIsCod,
      isDFOD: this.shipmentList[this.indexElement].shipmentIsDfod
    };
  }

  get payloadSectionThree() {
    return {
      sttPieces: this.convertedArr,
      isDO: this.shipmentList[this.indexElement].isDO,
      isMixpack: false,
      codAmount:
        this.shipmentList[this.indexElement].shipmentIsDfod ||
        this.shipmentList[this.indexElement].codDfodValue === "non-cod"
          ? 0
          : Number(
              this.shipmentList[this.indexElement].codAmount
                .toString()
                .replace(/[.]/g, "")
            ),
      codFee: Number(
        this.shipmentList[this.indexElement].codFee
          .toString()
          .replace(/[.]/g, "")
      ),
      shipmentPackageId: this.shipmentList[this.indexElement].shipmentPacketId,
      shipmentId: this.shipmentList[this.indexElement].shipmentAlgoId,
      sttId: this.shipmentList[this.indexElement].STT?.sttId,
      sttPiecePerPack: Number(
        this.shipmentList[this.indexElement].piecePerPack
      ),
      sttNextCommodity: this.shipmentList[this.indexElement].nextCommodity,
      otherShipperTicketCode: this.shipmentList[this.indexElement].dataRaw
        ?.other_shipper_ticket_code,
      postalCodeDestination: this.shipmentList[this.indexElement]
        .postalCodeDestination?.id,
      sttGrossWeight: Number(
        this.totalGrossWeight.toString().replace(",", ".")
      ),
      sttVolumeWeight: Number(
        this.totalVolumeWeight.toString().replace(",", ".")
      ),
      sttChargeableWeight: this.isCampaign
        ? Number(this.totalCampaignChargeableWeight)
        : Number(this.totalChargeableWeight.toString().replace(",", ".")),
      sttClientIdBranch: this.shipmentList[this.indexElement]
        .shipmentClientIdBranch,
      shipmentC1Cod: this.shipmentC1Cod,
      promoPrefix: this.promoPrefix,
      codHandling: this.shipmentC1Cod
        ? this.shipmentList[this.indexElement].shipmentCodHandling
        : "",
      discountFavoritePercentage: this.isShipmentFavorite
        ? this.shipmentList[this.indexElement].discountFavoritePercentage
        : 0,
      sttWeightAttachFiles: this.storageImages.filter(key => key !== ""),
      sttRecipientAddressType: this.shipmentList[this.indexElement]
        .sttRecipientAddressType
    };
  }

  payloadCommodityCode(commodityCode: any) {
    let payload;
    if (typeof commodityCode === "object") {
      payload = commodityCode.code;
    } else if (!this.shipmentList[this.indexElement].shipmentCommodityCode) {
      payload = this.enabledCommodityT1B1B2 ? "" : "GEN";
    } else {
      payload = this.shipmentList[this.indexElement].shipmentCommodityCode;
    }

    return payload;
  }

  addKGs() {
    const weight = (10 * 10 * 10) / this.division;
    const splitVolumeWeight = weight
      .toString()
      .replace(".", ",")
      .split(",");
    const front = splitVolumeWeight[0];
    const behind = splitVolumeWeight[1]
      ? "," + splitVolumeWeight[1].slice(0, 3)
      : "";
    if (this.listKGs.length > 14) {
      return;
    }
    this.listKGs.push({
      grossWeight: 0,
      dimension: {
        length: 10,
        width: 10,
        height: 10
      },
      volumeWeight: weight,
      convertVolumeWeight: front + behind,
      errorMaxPerPieces: false,
      isDimensionDisabled: this.configDisableDimension,
      warningHwsSingle: false,
      hwsError: false
    });
  }

  get isMaxAddKgs() {
    return this.listKGs.length < 15;
  }

  get validateTotalVolumeWeight(): boolean {
    let total = 0;
    this.listKGs.map((e: any) => (total += Number(e.volumeWeight)));
    return total > maxKg;
  }

  onCloseCommodity() {
    this.isOpenCommodity = false;
  }

  getCommodityList() {
    CommodityController.getCommodityList(new RequestListCommodity());
  }

  fetchCommodityList = debounce((search: string) => {
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search
      })
    );
  }, 250);

  get isErrorCommodity() {
    if (
      typeof this.shipmentList[this.indexElement].shipmentCommodityName ===
      "string"
    ) {
      return false;
    }
    return (
      JSON.parse(
        JSON.stringify(
          this.shipmentList[this.indexElement].shipmentCommodityName
        )
      ).status === "inactive"
    );
  }

  get checkIsDO() {
    return !(
      this.shipmentList[this.indexElement].isDO &&
      !this.payload.referenceExternal
    );
  }

  get isFormValid() {
    return !!(
      this.formValidationShipmentDetail &&
      this.formValidationShipmentGood &&
      this.fomValidationOthers &&
      this.isFormValidationError &&
      !this.disableSubmitForHWS
    );
  }

  get formValidationShipmentDetail() {
    return (
      this.payload.destinationCity &&
      this.payload.destinationDistrict &&
      this.payload.phoneSender &&
      this.payload.phoneReceiver &&
      this.payload.sender &&
      this.payload.receiver &&
      this.payload.addressSender &&
      this.payload.addressReceiver
    );
  }

  get formValidationShipmentGood() {
    const commodity =
      typeof this.payload.commodityCode === "object"
        ? this.payload.commodityCode.code
        : this.payload.commodityCode;
    return (
      this.payload.productName &&
      commodity &&
      this.payload.insurance &&
      this.checkIntersection() &&
      this.checkIsDO &&
      this.isVendorNinjaValid
    );
  }

  get fomValidationOthers() {
    return (
      this.isFtzRequiredForm &&
      this.isEstimationGoodMandatory &&
      this.listKGs.length > 0 &&
      this.isCourierRecommendationRequired &&
      !this.validateTotalVolumeWeight &&
      this.isValidPostalCodeDestination
    );
  }

  get isFormValidationError() {
    return (
      !this.error.maxTotalGrossWeight &&
      !this.error.maxTotalVolumeWeight &&
      !this.error.maxPerPieces &&
      this.errorDetailShipment &&
      !this.errorCHWeight.status &&
      !this.errorEstimationPrice &&
      !this.productErrorDFOD &&
      !this.destinationErrorDFOD
    );
  }

  get errorDetailShipment() {
    return (
      !this.error.product &&
      !this.error.insurance &&
      !this.errorPhoneShipper &&
      !this.errorPhoneConsignee &&
      !this.error.phoneCodeSender &&
      !this.error.phoneCodeReceiver &&
      !this.error.phoneCharMinimalSender &&
      !this.error.phoneCharMinimalReceiver
    );
  }

  checkIntersection() {
    const result = true;
    for (const el of this.convertedArr) {
      if (
        this.piecesAreZero(
          el.stt_piece_gross_weight,
          el.stt_piece_width,
          el.stt_piece_length,
          el.stt_piece_height
        )
      ) {
        return false;
      }
    }
    return result;
  }

  piecesAreZero(weight: number, width: number, length: number, height: number) {
    return weight === 0 || width === 0 || length === 0 || height === 0;
  }

  get manualBooking(): any {
    return BookingController.manualBooking;
  }

  get commodities() {
    return CommodityController.commodities.data.map(item => {
      return {
        id: item.commodity_id,
        name: item.commodity_name,
        code: item.commodity_code,
        status: item.commodity_status
      };
    });
  }

  get isLoadingCommodity() {
    return CommodityController.isLoading;
  }

  get arrGrossWeight() {
    return this.listKGs.map((key: any) => {
      return Number(key.grossWeight.toString().replace(/,/g, "."));
    });
  }

  get allowToAddMoreKoliVolume(): boolean {
    let total = 0;
    this.listKGs.map((e: any) => (total += Number(e.volumeWeight)));
    return total >= maxKg;
  }

  get allowToAddMoreKoli(): boolean {
    let total = 0;
    this.listKGs.map((e: any) => (total += Number(e.grossWeight)));
    return total >= maxKg;
  }

  get totalGrossWeight() {
    const result = this.calculateGrossVolume(this.arrGrossWeight);
    if (Number(result.replace(",", ".")) > 400) {
      this.error.maxTotalGrossWeight = true;
    } else {
      this.error.maxTotalGrossWeight = false;
    }

    return result;
  }

  get arrVolumeWeight() {
    return this.listKGs.map((key: any) => {
      key.volumeWeight = Number(key.volumeWeight.toString().replace(/,/g, "."));
      return key.volumeWeight;
    });
  }

  get totalVolumeWeight() {
    const result = this.calculateGrossVolume(this.arrVolumeWeight);
    if (this.isCourierRecommendation) {
      if (Number(result.replace(",", ".")) > 400) {
        this.error.maxTotalVolumeWeight = true;
      } else {
        this.error.maxTotalVolumeWeight = false;
      }
    }
    return result;
  }

  calculateGrossVolume(value: any) {
    let indicator = 0;
    value.map((key: any) => {
      indicator += key;
      return indicator;
    });
    const split = parseFloat(Number(indicator).toFixed(3))
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 3) : "";
    const result = front + behind;

    return result;
  }

  removeKGs(index: number) {
    this.isHoverDelete = false;
    this.tempIndexToRemove = index;
    if (this.tempIndexToRemove > -1) {
      this.listKGs.splice(this.tempIndexToRemove, 1);
      this.tempIndexToRemove = -1;
    }
    this.setValidateHWS();
  }

  isHoverEnter(indexParam: number) {
    this.isHoverDelete = true;
    this.cacheIndex = indexParam;
  }

  formatted(value: string) {
    return formattedNPWP(value.replace(/\D+/g, ""));
  }

  indexVolume = 0;
  get division() {
    const volume = volumeWeightForProduct(
      this.shipmentList[this.indexElement].shipmentProduct
    );
    return volume;
  }
  calculationVolumeWeight(index: number) {
    this.indexVolume = index;
    const dimension =
      this.listKGs[index].dimension.length *
      this.listKGs[index].dimension.width *
      this.listKGs[index].dimension.height;

    const volume = volumeWeightForProduct(
      this.shipmentList[this.indexElement].shipmentProduct
    );
    this.listKGs[index].volumeWeight = dimension / volume;

    const split = this.listKGs[index].volumeWeight
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 3) : "";
    this.listKGs[index].convertVolumeWeight = front + behind;
  }

  isMaxLengthGrossWeight: any = null;
  formatFloat(value: string) {
    const regex = value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");

    this.isMaxLengthGrossWeight = maxLengthWeight(regex) + 1;
    return regex;
  }

  errorMaxPerPieces(value: any, index: number) {
    if (typeof value === "string" && Number(value.replace(",", ".")) > 150) {
      this.listKGs[index].errorMaxPerPieces = true;
      this.error.maxPerPieces = true;
    } else {
      this.listKGs[index].errorMaxPerPieces = false;
      this.error.maxPerPieces = false;
    }
    return this.listKGs[index].errorMaxPerPieces;
  }

  validatePhoneSender(value: string) {
    this.shipmentList[
      this.indexElement
    ].shipmentSenderPhone = this.validatePhones(value);
    this.error.phoneSender = !/^\d+$/.test(value);
  }
  validatePhoneReceiver(value: string) {
    this.shipmentList[
      this.indexElement
    ].shipmentRecipientPhone = this.validatePhones(value);
    this.error.phoneReceiver = !/^\d+$/.test(value);
  }

  validatePhones(value: string) {
    let phoneNumber;
    const phoneArray = value.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = value.slice(0, -1);
      phoneNumber = newPhone;
    } else {
      phoneNumber = value;
    }

    return phoneNumber;
  }

  detectPhoneNumber(phoneArray: Array<string>) {
    return phoneArray.find(
      () => phoneArray[0] === "0" && phoneArray[1] === "8"
    );
  }

  validateTaxNumber(value: string) {
    this.error.taxNumber = !/[0-9-.]+/.test(value);
    if (value.length < 20) {
      this.error.taxNumber = true;
    }
  }

  handleErrorTaxNumber() {
    if (!this.shipmentList[this.indexElement].shipmentNpwpNumber) {
      this.error.taxNumber = false;
    }
  }

  formatEstimationPrice(value: string) {
    return formatInputMoney(value.replace(/\D+/g, ""));
  }

  formatPiecePerPack(value: string) {
    return value.replace(/\D+/g, "").replace(/\D|^0+/g, "");
  }

  // route navigation
  goBack() {
    this.$router.push("/shipment/booking");
  }
  onOpenSelect() {
    this.isStatusGoods = true;
  }

  onCloseSelect() {
    this.isStatusGoods = false;
  }

  onSelectStatus(key: string, value: string) {
    this.shipmentList[this.indexElement].shipmentGoodsStatus = value;
  }

  filterProductsDelivery = debounce((search: string) => {
    BookingController.getProductsDelivery({
      search: search,
      page: 1,
      limit: 99,
      status: "active"
    });
  }, 250);

  get productsDelivery() {
    return BookingController.productsDelivery.data.map(e => ({
      id: e.id,
      name: e.name,
      status: e.status
    }));
  }

  get isLoadingProduct() {
    return BookingController.loadingProductsDelivery;
  }

  async onSelectProduct(obj: any) {
    if (obj) {
      if (obj.status === "ACTIVE") {
        if (
          this.shipmentList[this.indexElement].shipmentProduct !==
            "INTERPACK" &&
          obj.name === "INTERPACK"
        ) {
          this.listKGs = [this.listKGs[0]];
        }

        this.shipmentList[this.indexElement].shipmentProduct = obj.name;
        for (let i = 0; i < this.listKGs.length; i++) {
          this.calculationVolumeWeight(i);
        }
        this.shipmentList[this.indexElement].postalCodeDestination = "";
        this.callZipCode();

        this.shipmentList[this.indexElement].codDfodValue = "non-cod";
        this.codStatus();
        this.dfodStatus();
        await this.getMinimumHeavyWeight();
        this.setValidateHWS();
      } else {
        this.error.product = true;
      }
    }

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
  }

  // insurance types
  getInsurancesTypeDelivery() {
    BookingController.getInsuranceTypesDelivery({
      insuranceConfig: 2
    });
  }

  get listInsurance() {
    const defaultChoices: any = [
      {
        name: "Pilih Asuransi",
        value: "",
        status: ""
      }
    ];

    const insurance = BookingController.insuranceTypeDelivery.data.map(
      (e: any) => ({
        name: e.name,
        value: e.value,
        status: e.status
      })
    );

    return defaultChoices.concat(insurance);
  }

  onOpenSelectInsurance() {
    this.isOpenSelectInsurance = true;
  }

  onCloseSelectInsurance() {
    this.isOpenSelectInsurance = false;
  }

  onSelectStatusInsurance(name: string, value: string) {
    this.shipmentList[this.indexElement].shipmentInsurance = value;

    const insurance = this.listInsurance.find(
      (item: any) => item.value == value
    );
    this.error.insurance = insurance?.status === "Inactive";
  }

  closeModalError() {
    ShipmentBookingController.setError(false);

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(true);
    }
  }

  //customer sender receiver
  get senders() {
    return this.getPhoneNumbers;
  }
  get receivers() {
    return this.getPhoneNumbers;
  }
  get getPhoneNumbers() {
    const list = BookingController.customerList.data;
    if (list.length !== 0) {
      return BookingController.customerList.data.map(item => {
        return {
          ...item,
          customerPhoneNumber: `${item.customerPhoneNumber}`
        };
      });
    } else {
      return list;
    }
  }
  getCustomerList() {
    BookingController.getCustomerList({ search: "", cache: true });
  }
  setNameCustomer(search: string, type: string) {
    if (type === "senders") {
      this.shipmentList[this.indexElement].shipmentSenderName = search;
    }
    if (type === "receivers") {
      this.shipmentList[this.indexElement].shipmentRecipientName = search;
    }
    if (search.length === 0) {
      return;
    }
    this.fetchCustomer(search);
  }
  setCustomer(search: string, type: string) {
    if (type === "senders") {
      this.shipmentList[this.indexElement].shipmentSenderName = search;
    }
    if (type === "receivers") {
      this.shipmentList[this.indexElement].shipmentRecipientName = search;
    }
  }
  fetchCustomer = debounce((search: string) => {
    if (search.length > 2) {
      BookingController.getCustomerList({ search: search, cache: true });
    }
  }, 1000);
  get isLoadingCustomer() {
    return BookingController.isLoadingCustomer;
  }
  onChooseSenders(item: any) {
    if (!item) {
      return false;
    }
    if (item) {
      item = {
        ...item,
        customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
        customerPhoneflag: codePhoneCountry(item.customerPhoneNumber).flag,
        customerPhoneNumber: codePhoneCountry(item.customerPhoneNumber).number
      };
    } else {
      item = {
        customerId: 0,
        customerName: "",
        customerAddress: this.shipmentList[this.indexElement]
          .shipmentSenderAddress,
        customerPhoneCode: this.shipmentList[this.indexElement]
          .shipmentSenderPhoneCode,
        customerPhoneflag: this.shipmentList[this.indexElement]
          .shipmentSenderPhoneFlag,
        customerPhoneNumber: this.shipmentList[this.indexElement]
          .shipmentSenderPhone
      };
    }

    this.shipmentList[this.indexElement].shipmentSenderName = item.customerName;
    this.shipmentList[this.indexElement].shipmentSenderAddress =
      item.customerAddress;
    this.shipmentList[this.indexElement].shipmentSenderPhoneCode =
      item.customerPhoneCode;
    this.shipmentList[this.indexElement].shipmentSenderPhoneFlag =
      item.customerPhoneflag;
    this.shipmentList[this.indexElement].shipmentSenderPhone =
      item.customerPhoneNumber;

    this.validatePhoneSender(
      this.shipmentList[this.indexElement].shipmentSenderPhone
    );

    if (item.customerPhoneNumber.length < 5) {
      this.error.phoneCharMinimalSender = true;
    } else {
      this.error.phoneCharMinimalSender = false;
    }
  }
  onChooseReceivers(item: any) {
    if (!item) {
      return false;
    }
    if (item) {
      item = {
        ...item,
        customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
        customerPhoneflag: codePhoneCountry(item.customerPhoneNumber).flag,
        customerPhoneNumber: item.customerPhoneNumber
      };
    } else {
      item = {
        customerId: 0,
        customerName: "",
        customerAddress: this.shipmentList[this.indexElement]
          .shipmentSenderAddress,
        customerPhoneCode: this.shipmentList[this.indexElement]
          .shipmentRecipientPhoneCode,
        customerPhoneflag: this.shipmentList[this.indexElement]
          .shipmentRecipientPhoneFlag,
        customerPhoneNumber: this.shipmentList[this.indexElement]
          .shipmentRecipientPhone
      };
    }

    this.shipmentList[this.indexElement].shipmentRecipientName =
      item.customerName;
    this.shipmentList[this.indexElement].shipmentRecipientAddress =
      item.customerAddress;
    this.shipmentList[this.indexElement].shipmentRecipientPhoneCode =
      item.customerPhoneCode;
    this.shipmentList[this.indexElement].shipmentRecipientPhoneFlag =
      item.customerPhoneflag;
    this.shipmentList[this.indexElement].shipmentRecipientPhone =
      item.customerPhoneNumber;

    this.validatePhoneReceiver(
      this.shipmentList[this.indexElement].shipmentRecipientPhone
    );

    if (item.customerPhoneNumber.length < 5) {
      this.error.phoneCharMinimalReceiver = true;
    } else {
      this.error.phoneCharMinimalReceiver = false;
    }
  }

  onSaveCustomer = debounce((event: any, type: string) => {
    if (!event.target.checked) {
      return;
    }
    if (type === "sender") {
      if (
        this.shipmentList[this.indexElement].shipmentSenderName &&
        this.shipmentList[this.indexElement].shipmentSenderPhone
      ) {
        BookingController.addCustomer({
          name: this.shipmentList[this.indexElement].shipmentSenderName,
          phone: `${this.shipmentList[
            this.indexElement
          ].shipmentSenderPhoneCode.substring(1)}${
            this.shipmentList[this.indexElement].shipmentSenderPhone
          }`,
          address: this.shipmentList[this.indexElement].shipmentSenderAddress
        });
      }
    } else if (type === "receiver") {
      if (
        this.shipmentList[this.indexElement].shipmentRecipientName &&
        this.shipmentList[this.indexElement].shipmentRecipientPhone
      ) {
        BookingController.addCustomer({
          name: this.shipmentList[this.indexElement].shipmentRecipientName,
          phone: `${this.shipmentList[
            this.indexElement
          ].shipmentRecipientPhoneCode.substring(1)}${
            this.shipmentList[this.indexElement].shipmentRecipientPhone
          }`,
          address: this.shipmentList[this.indexElement].shipmentRecipientAddress
        });
      }
    }
  }, 1000);

  get accountRefId(): number {
    return AccountController.accountData.account_type_detail.id;
  }

  get origin() {
    const detail = LocationController.districtDetail;
    return {
      id: detail.id,
      name: `${detail.name}, ${detail.cityName}`,
      code: `${detail.code}`,
      cityCode: `${detail.cityCode}`
    };
  }

  get destination() {
    const detail = LocationController.destinationDistrict;
    if (detail) {
      return {
        id: detail.id,
        name: detail.name,
        code: detail.code,
        cityCode: detail.cityCode,
        type: detail.type,
        vendorCode: detail.vendorCode,
        isCod: detail.isCod
      };
    }

    return {
      id: 0,
      name: "",
      code: "",
      cityCode: "",
      type: "",
      vendorCode: ""
    };
  }

  isHaveChosen = false;
  async setDestination(val: any) {
    LocationController.setDestinationDistrict(val);
    this.setVendorNinja();
    this.handleErrorDestinationCity();
    this.isHaveChosen = true;

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
    this.callZipCode();
    if (val) {
      this.loadUpsellingProduct();
    } else {
      this.resetProductUpselling();
    }
  }

  async loadUpsellingProduct() {
    if (!this.isDisableProduct || this.isShipmentCod) {
      this.shipmentList[this.indexElement].shipmentProduct = "";
      const visiblePricePosterProduct = prefixAlgoEstimationShow(
        this.shipmentList[this.indexElement].shipmentAlgoId
      );
      const renderPoster: any = this.$refs.renderPoster;
      await renderPoster?.getPosterProduct(
        this.shipmentList[this.indexElement].shipmentAlgoId,
        this.payload.originDistrict,
        this.payload.destinationDistrict,
        visiblePricePosterProduct,
        2311,
        "client"
      );

      if (this.isShipmentCod) {
        renderPoster?.setProductShipmentCod(
          this.shipmentList[this.indexElement].shipmentProductTemp.toLowerCase()
        );
      }
    }
  }

  resetProductUpselling() {
    if (!this.isDisableProduct) {
      const renderPoster: any = this.$refs.renderPoster;
      renderPoster?.resetAllProduct();
    }
  }

  async callZipCode() {
    if (
      this.destination.name &&
      this.shipmentList[this.indexElement].shipmentProduct === "INTERPACK"
    ) {
      const responseDistrictData = await LocationController.getDetailDistrict({
        id: this.destination.id,
        notSaveDetail: true
      });
      this.postalCodeDestinationOptions = responseDistrictData.originListZipCode.map(
        (item: any) => {
          return {
            id: item,
            name: item
          };
        }
      );
    }
  }

  async fetchDetailDistrictDestination() {
    const destinationDistrictCode = this.shipmentList[this.indexElement]
      .shipmentDestinationDistrict.code;
    if (destinationDistrictCode) {
      await LocationController.getDetailDistrictByDistrictCode({
        districtCode: destinationDistrictCode
      });
      this.postalCodeDestinationOptions =
        LocationController.destinationDistrict.originListZipCode;
    } else {
      LocationController.setEmptyDestinationDistrict();
    }
  }

  setVendorNinja() {
    ShipmentBookingController.setVendorNinja(this.destination);
  }
  get isVendorNinja() {
    return ShipmentBookingController.isVendorNinja;
  }
  get isVendorNinjaValid() {
    if (this.isVendorNinja) {
      return this.listKGs?.length === 1;
    }
    return true;
  }

  // Destination City
  destinationCityList: Array<any> = [];
  fetchDestinationCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true
    });
    this.destinationCityList = this.filterDistrict(response);
  }, 250);

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.code}`,
        cityCode: `${e.cityCode}`,
        status: e.status,
        type: e.type,
        vendor_code: e.vendorCode,
        isCod: e.isCod
      }));
  }

  get isLoadingDestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  get errorDestinationCity() {
    return BookingController.errorDestination;
  }

  handleErrorDestinationCity() {
    BookingController.setErrorDestination(false);
  }

  get disableSaveSender() {
    return (
      !this.shipmentList[this.indexElement].shipmentSenderName ||
      !this.shipmentList[this.indexElement].shipmentSenderPhone ||
      !this.shipmentList[this.indexElement].shipmentSenderAddress ||
      this.error.phoneSender
    );
  }

  get disableSaveReceiver() {
    return (
      !this.shipmentList[this.indexElement].shipmentRecipientName ||
      !this.shipmentList[this.indexElement].shipmentRecipientPhone ||
      !this.shipmentList[this.indexElement].shipmentRecipientAddress ||
      this.error.phoneReceiver
    );
  }

  get accountRefAndCommodity() {
    return {
      accountRefId: this.manualBooking.sttManual.id,
      commodityId: this.manualBooking.commodityCode.id
    };
  }

  get disabledButtonErrorTokpedWeight() {
    return (
      this.errorMaxChWeight &&
      (this.isErrorMaxChWeightDimension || this.isErrorMaxChWeightGoods)
    );
  }

  get isDisabledButton() {
    return (
      this.isEmbargoCommodity ||
      !this.isFormValid ||
      this.isErrorCommodity ||
      this.isShipperAndConsigneeAddressIdentical ||
      this.disabledButtonErrorTokpedWeight ||
      (this.isShipmentCASameday && this.totalChargeableWeight > 5)
    );
  }

  get urlVersion() {
    return isUrlUpdateVersion("customer");
  }

  isSubmit = false;
  onEnterBooking(event: any) {
    this.isSubmit = false;
    if (event.key === "Enter") {
      if (this.isDisabledButton) {
        this.setFieldIndex(19);
        return;
      }
      this.enterbookingSuccess();
    }
  }

  enterbookingSuccess() {
    if (!this.isSubmit && !this.loadingModal) {
      if (!this.openSuccess) {
        this.booking();
        return;
      }
    }
  }

  get loadingModal() {
    return MainAppController.loadingModal;
  }

  created() {
    window.addEventListener("keydown", this.onEnterBooking);
    this.onCheckDisableWeightAdjustment();
  }

  unmounted() {
    window.removeEventListener("keydown", this.onEnterBooking);
  }

  booking() {
    this.isSubmit = true;
    const payload = {
      sttManual: this.payload.sttManual,
      referenceExternal: this.payload.referenceExternal,
      productName: this.payload.productName,
      originCity: this.payload.originCity,
      originDistrict: this.payload.originDistrict,
      destinationCity: this.payload.destinationCity,
      destinationDistrict: this.payload.destinationDistrict,
      phoneSender: this.payload.phoneSender,
      phoneReceiver: this.payload.phoneReceiver,
      receiver:
        typeof this.payload.receiver === "object"
          ? this.payload.receiver.customerName
          : this.payload.receiver,
      sender:
        typeof this.payload.sender === "object"
          ? this.payload.sender.customerName
          : this.payload.sender,
      addressReceiver: this.payload.addressReceiver,
      addressReceiverType: "",
      addressSender: this.payload.addressSender,
      commodityCode: this.payload.commodityCode,
      insurance: this.payload.insurance,
      statusGoods: this.payload.statusGoods,
      estimationPrice: this.payload.estimationPrice,
      taxNumber: this.payload.taxNumber,
      isWoodpack: this.payload.isWoodpack,
      isCOD: this.payload.isCOD,
      isDFOD: this.payload.isDFOD,
      codHandling: this.payload.codHandling,
      isSaveSender: this.payload.isSaveSender,
      isSaveReceiver: this.payload.isSaveReceiver,
      sttPieces: this.payload.sttPieces,
      isDO: this.payload.isDO,
      isMixpack: this.payload.isMixpack,
      codAmount: this.payload.codAmount,
      codFee: this.payload.codFee,
      shipmentPackageId: this.payload.shipmentPackageId,
      shipmentId: this.payload.shipmentId,
      sttPiecePerPack: Number(this.payload.sttPiecePerPack),
      sttNextCommodity: this.payload.sttNextCommodity,
      clientId: 0,
      otherShipperTicketCode: this.shipmentList[this.indexElement].dataRaw
        ?.other_shipper_ticket_code,
      postalCodeDestination: this.payload.postalCodeDestination,
      discountFavoritePercentage: this.payload.discountFavoritePercentage,
      sttWeightAttachFiles: this.payload.sttWeightAttachFiles,
      sttRecipientAddressType: this.payload.sttRecipientAddressType,
      isFromBooking: true
    };
    ShipmentBookingController.addShipmentBooking(payload);
  }

  get openSuccess() {
    return ShipmentBookingController.showModalSuccess;
  }

  get messageSuccess() {
    return ShipmentBookingController.messageSuccess;
  }

  onCloseSuccess() {
    this.$emit("updateStatus", true);
    ShipmentBookingController.setShowModalSuccess(false);
    ShipmentBookingController.setShowModalShipmentBooking(false);
    BookingController.setGuardBookingSubmit(false);
  }

  get isDO() {
    return AccountController.accountData.account_type === "client"
      ? AccountController.accountData.account_type_detail.isDO
      : false;
  }

  get isMixpack() {
    return AccountController.accountData.account_type === "client"
      ? AccountController.accountData.account_type_detail.isMixpack
      : false;
  }

  get reRenderManualBooking() {
    return BookingController.reRenderManualBooking;
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type
    };
  }

  get isWoodPackingStatus() {
    return !this.shipmentList[this.indexElement].shipmentAbleToWoodpacking;
  }

  get isCODStatus() {
    if (this.isShipmentFavorite) {
      return false;
    } else {
      return (
        !this.shipmentList[this.indexElement].shipmentAbleToCod ||
        this.shipmentC1Cod
      );
    }
  }

  //phone validation
  get watchPhoneValidation() {
    const sender = this.payload.phoneSender;
    const receiver = this.payload.phoneReceiver;
    const requestErrorMessage = debounce(
      (phoneSender: string, phoneReceiver: string) => {
        if (phoneSender.length > 3 || phoneReceiver.length > 3) {
          BookingController.validatePhone({
            shipmentId: this.payload.shipmentId,
            phoneSender: phoneSender.length > 3 ? phoneSender : "",
            phoneReceiver: phoneReceiver.length > 3 ? phoneReceiver : ""
          });
        }
      },
      100
    );
    return prefixAlgoForSameCustomer(
      this.detailShipment.shipmentAlgoId,
      requestErrorMessage(sender, receiver)
    );
  }

  get errorPhoneShipper() {
    let phoneShipper = "";
    if (BookingController.errorPhoneNumber) {
      if (
        BookingController.errorPhoneNumber.toLowerCase().includes("pengirim")
      ) {
        phoneShipper = BookingController.errorPhoneNumber;
      }
    }

    return phoneShipper;
  }

  get errorPhoneConsignee() {
    let phoneConsignee = "";
    if (BookingController.errorPhoneNumber) {
      if (
        BookingController.errorPhoneNumber.toLowerCase().includes("penerima")
      ) {
        phoneConsignee = BookingController.errorPhoneNumber;
      }
    }

    return phoneConsignee;
  }

  // error embargo
  get isErrorEmbargo() {
    return BookingController.isErrorEmbargo;
  }

  errorEmbargo = {
    originCity: false,
    destinationCity: false,
    productType: false,
    comodity: false,
    weightStatus: false,
    lengthStatus: false
  };

  onUpdateEmbargoStatusFail(value: string, index: number) {
    this.onSetDisableDimension(value, index);
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
    this.isErrorMaxChWeightDimension =
      typeof value === "object" && this.isPrefixTokped;
    this.isErrorMaxChWeightGoods =
      typeof value === "string" && this.isPrefixTokped;
  }

  updateStatusErrorEmbargo(status: boolean) {
    this.errorEmbargo.originCity = status;
    this.errorEmbargo.destinationCity = status;
    this.errorEmbargo.productType = status;
    this.errorEmbargo.comodity = status;
    this.errorEmbargo.weightStatus = status;
    this.errorEmbargo.lengthStatus = status;
  }

  prefixAlgoEstimationShow(algoId: string) {
    return prefixAlgoEstimationShow(algoId);
  }

  get isShipperAndConsigneeAddressIdentical() {
    if (
      this.shipmentList[this.indexElement].shipmentAlgoId.slice(0, 2) === "AO"
    ) {
      return false;
    }
    if (
      this.shipmentList[this.indexElement].shipmentSenderAddress === "" ||
      this.shipmentList[this.indexElement].shipmentRecipientAddress === ""
    ) {
      return false;
    }
    return (
      this.shipmentList[
        this.indexElement
      ].shipmentSenderAddress.toLowerCase() ===
      this.shipmentList[
        this.indexElement
      ].shipmentRecipientAddress.toLowerCase()
    );
  }

  get isFtzRequiredForm() {
    if (this.isFtzAccount) {
      return !!(
        this.shipmentList[this.indexElement].shipmentGoodsStatus &&
        this.shipmentList[this.indexElement].piecePerPack &&
        this.shipmentList[this.indexElement].nextCommodity
      );
    }

    return true;
  }

  get isFtzAccount() {
    return (
      AccountController.accountData.account_location?.city_free_trade_zone ===
      "yes"
    );
  }

  get isEstimationGoodMandatory() {
    if (this.shipmentList[this.indexElement].shipmentInsurance === "free") {
      return this.payload.estimationPrice >= 0 || !this.payload.estimationPrice;
    } else {
      return !(
        this.payload.estimationPrice <= 0 || !this.payload.estimationPrice
      );
    }
  }

  get isShipmentInsuranceEnable() {
    return (
      SHIPMENT_ID_FAVOURITE.includes(
        this.shipmentList[this.indexElement].shipmentAlgoId.substr(0, 2)
      ) || this.isCourierRecommendation
    );
  }

  // error indicator mandatory
  currentFieldIndex = 0;
  setFieldIndex(index: number) {
    if (index > this.currentFieldIndex) {
      this.currentFieldIndex = index;
    }
  }
  get errorMandatory() {
    return [
      ...this.errorMandatoryDetailDelivery,
      ...this.errorMandatoryDetailGood
    ];
  }

  get errorMandatoryDetailDelivery() {
    return [
      ...this.errorMandatoryFirstColumns,
      ...this.errorMandatorySecondColumns
    ];
  }

  get errorMandatoryDetailGood() {
    return [
      ...this.errorMandatoryThirdColumns,
      ...this.errorMandatoryFourthColumns
    ];
  }

  get errorMandatoryFirstColumns() {
    return [
      !this.payload.productName && this.currentFieldIndex > 0,
      !this.payload.referenceExternal && this.currentFieldIndex > 1,
      false,
      !this.payload.sender && this.currentFieldIndex > 3,
      !this.payload.receiver && this.currentFieldIndex > 4,
      !this.shipmentList[this.indexElement].shipmentSenderPhone &&
        this.currentFieldIndex > 5
    ];
  }

  get errorMandatorySecondColumns() {
    return [
      !this.shipmentList[this.indexElement].shipmentRecipientPhone &&
        this.currentFieldIndex > 6,
      !this.payload.addressSender && this.currentFieldIndex > 7,
      !this.payload.addressReceiver && this.currentFieldIndex > 8,
      !this.payload.destinationDistrict && this.currentFieldIndex > 9,
      !this.payload.commodityCode && this.currentFieldIndex > 10
    ];
  }

  get errorMandatoryThirdColumns() {
    return [
      !this.payload.statusGoods &&
        (this.isFtzAccount || this.isCourierRecommendation) &&
        this.currentFieldIndex > 11,
      !this.payload.insurance && this.currentFieldIndex > 12,
      this.errorMandatoryEstimationPriceCondition,
      !this.payload.sttGrossWeight && this.currentFieldIndex > 14,
      !this.payload.sttChargeableWeight && this.currentFieldIndex > 15
    ];
  }

  get errorMandatoryFourthColumns() {
    return [
      !this.payload.sttPiecePerPack &&
        this.isFtzAccount &&
        this.currentFieldIndex > 16,
      !this.payload.sttNextCommodity &&
        this.isFtzAccount &&
        this.currentFieldIndex > 17
    ];
  }

  get errorMandatoryEstimationPriceCondition() {
    return (
      !this.payload.estimationPrice &&
      this.shipmentList[this.indexElement].shipmentInsurance !== "free" &&
      this.currentFieldIndex > 13
    );
  }

  // kurir rekomendasi
  get isCourierRecommendation() {
    return !!this.shipmentList[this.indexElement]?.dataRaw?.ticket_code;
  }

  get isCourierRecommendationRequired() {
    if (this.isCourierRecommendation) {
      return !!this.shipmentList[this.indexElement].shipmentGoodsStatus;
    }

    return true;
  }

  // tooltip
  toggleTooltip(event: any) {
    const refs: any = this.$refs;
    refs["opTooltip"].toggle(event);
  }

  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }

  // sender
  get errorPhoneSender() {
    return {
      errorPhone: this.error.phoneSender,
      errorMandatory: this.errorMandatory[5],
      errorPhoneLabel: this.errorPhoneShipper,
      errorPhoneCode: this.error.phoneCodeSender,
      phoneCharMinimal: this.error.phoneCharMinimalSender
    };
  }

  setErrorPhoneSender(val: any) {
    this.error.phoneSender = !/^\d+$/.test(val);
  }

  setErrorPhoneCodeSender(status: boolean) {
    this.error.phoneCodeSender = status;
  }

  setErrorCharMinimalSender(status: boolean) {
    this.error.phoneCharMinimalSender = status;
  }

  setPhoneSender(val: string) {
    this.shipmentList[this.indexElement].shipmentSenderPhone = val;
  }

  setPhoneCodeSender(val: string) {
    this.shipmentList[this.indexElement].shipmentSenderPhoneCode = val;
  }

  setPhoneFlagSender(val: string) {
    this.shipmentList[this.indexElement].shipmentSenderPhoneFlag = val;
  }

  // receiver
  get errorPhoneReceiver() {
    return {
      errorPhone: this.error.phoneReceiver,
      errorMandatory: this.errorMandatory[6],
      errorPhoneLabel: this.errorPhoneConsignee,
      errorPhoneCode: this.error.phoneCodeReceiver,
      phoneCharMinimal: this.error.phoneCharMinimalReceiver
    };
  }

  setErrorPhoneReceiver(val: any) {
    this.error.phoneReceiver = !/^\d+$/.test(val);
  }

  setErrorPhoneCodeReceiver(status: boolean) {
    this.error.phoneCodeReceiver = status;
  }

  setErrorCharMinimalReceiver(status: boolean) {
    this.error.phoneCharMinimalReceiver = status;
  }

  setPhoneReceiver(val: string) {
    this.shipmentList[this.indexElement].shipmentRecipientPhone = val;
  }

  setPhoneCodeReceiver(val: string) {
    this.shipmentList[this.indexElement].shipmentRecipientPhoneCode = val;
  }

  setPhoneFlagReceiver(val: string) {
    this.shipmentList[this.indexElement].shipmentRecipientPhoneFlag = val;
  }

  // postal code
  postalCodeDestinationOptions: any[] = [];
  setPostalCodeDestination(value: string) {
    if (value) {
      this.shipmentList[this.indexElement].postalCodeDestination = {
        id: value,
        name: value
      };

      return;
    }

    this.shipmentList[this.indexElement].postalCodeDestination = "";
  }
  get isValidPostalCodeDestination() {
    if (this.isPostalCodeFieldShow) {
      return !!this.shipmentList[this.indexElement].postalCodeDestination?.id;
    }
    return true;
  }
  get isPostalCodeFieldShow() {
    return this.shipmentList[this.indexElement].shipmentProduct === "INTERPACK";
  }

  // configurable rule product
  productConfigurationRuleList: ProductConfigurationRuleList = new ProductConfigurationRuleList();
  async fetchProductConfigurableRule() {
    try {
      this.productConfigurationRuleList = await ProductController.fetchProductConfigurationRule(
        new ProductConfigurationRuleRequestParams()
      );
    } catch (err) {
      this.productConfigurationRuleList = new ProductConfigurationRuleList();
    }
  }

  get isDisableAdjustPieces() {
    if (this.detailShipment.shipmentAlgoId.match(/^C1|^C2/gi)) {
      return false;
    }
    if (this.isEnablePiecesBooking) {
      return this.isEnablePiecesBooking;
    }
    return (
      this.handleDisabledInput ||
      this.detailShipment.shipmentProduct === "INTERPACK"
    );
  }

  estimateSla = {
    slaMin: 0,
    slaMax: 0,
    transport: ""
  };
  get isShowWarningSla() {
    return this.estimateSla.slaMin >= 7;
  }

  get discount() {
    return ShipmentBookingController.discount;
  }

  get errorProduct() {
    if (!this.errorDestintaionProductUpselling) {
      if (this.errorMandatory[0]) {
        return "Produk pengiriman wajib dipilih";
      }

      if (this.errorEmbargo.productType) {
        return "Kota tujuan pengiriman masih berstatus Embargo";
      }
    }

    return "";
  }

  errorDestintaionProductUpselling = false;
  setErrorDestintaionProductUpselling(status: boolean) {
    this.errorDestintaionProductUpselling = status;
    BookingController.setErrorDestination(status);
  }

  get isEditableForPrefixAR() {
    const prefix = this.detailShipment.shipmentAlgoId;
    return (
      (prefix.startsWith("ARA") || prefix.startsWith("ARB")) &&
      !this.shipmentList[this.indexElement].shipmentDestinationDistrict.id
    );
  }

  get isDisableProduct() {
    const productDisable = this.isEditableForPrefixAR
      ? false
      : this.handleDisabledInput;
    return this.isCourierRecommendation || productDisable;
  }

  mountedProductUpsellingDefault() {
    const renderPoster: any = this.$refs.renderPoster;
    renderPoster?.setActiveProductDisabled(
      this.shipmentList[this.indexElement].shipmentProduct.toLowerCase()
    );
  }

  get isShipmentCod() {
    return /^ARA|^ARB/.test(
      this.shipmentList[this.indexElement].shipmentAlgoId
    );
  }

  get shipmentC1Cod() {
    return (
      this.shipmentList[this.indexElement].shipmentAlgoId.startsWith(
        FlagsFeatureShipmentC1.feature_shipment_c1.getValue()
      ) && this.shipmentList[this.indexElement].shipmentIsCod
    );
  }

  get promoPrefix() {
    return prefixAlgoEstimationShow(
      this.shipmentList[this.indexElement].shipmentAlgoId
    ) || this.shipmentC1Cod
      ? promoPrefix(this.shipmentList[this.indexElement].shipmentAlgoId)
      : "";
  }

  get configDisableDimension() {
    return prefixDisableDimension(
      this.shipmentList[this.indexElement].shipmentAlgoId
    );
  }

  disabledWeightAdjustment = false;
  prefixList = "T1,TKLP,TKP,C1,C2,AD,ACA,ACB,AO,AI,ARA,ARB";
  totalChargeableWeightKeeped: any = 0;

  onShowSectionAdjustmentWeight(shipmentNo: any) {
    const resp = this.prefixList
      .split(",")
      .find((item: string) => shipmentNo.toUpperCase().startsWith(item));
    if (shipmentNo.startsWith("T")) {
      return true;
    }
    return !!resp;
  }

  prefixTokped = "T1,TKLP,TKP";
  get isPrefixTokped() {
    const shipmentId = this.isCourierRecommendation
      ? this.detailShipment.shipmentNoRefExt
      : this.detailShipment.shipmentAlgoId;
    const result = this.prefixTokped
      .split(",")
      .find((item: string) => shipmentId.toUpperCase().startsWith(item));
    return !!result;
  }

  onCheckDisableWeightAdjustment() {
    const shipmentId = this.isCourierRecommendation
      ? this.detailShipment.shipmentNoRefExt
      : this.detailShipment.shipmentAlgoId;
    const findPrefix = this.prefixList
      .split(",")
      .find((item: string) => shipmentId.toUpperCase().startsWith(item));
    this.disabledWeightAdjustment = !!findPrefix;
  }

  showViewAdjustment = false;
  onClickWeightAdjustment() {
    const refs: any = this.$refs;
    this.showViewAdjustment = true;
    if (refs) {
      refs["section-weight-adjustment"].onClickAccordion();
    }
  }

  async onUpload(params: UploadMultiple) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      if (params.file) {
        const fileLink = await BookingController.uploadHydraFile(
          new UploadHydraFileRequest({
            attachFile: params.file,
            folderPath: "stt/"
          })
        );
        this.storageImages[params.index] = fileLink.data;
        this.disabledWeightAdjustment =
          this.storageImages.filter((key: any) => key !== "").length < 2;
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, this.$t("bookingShipment.error.uploadPhoto"))
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  get maxPhoto() {
    return flags.flag_max_photo_for_weight_adjustment.getValue();
  }

  async onRemove(index: number) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      const del = await BookingController.deleteHydraFile(
        new DeleteHydraFileRequest({
          attachFiles: [this.storageImages[index]]
        })
      );
      const statusDel = del.data[this.storageImages[index]];
      if (statusDel) {
        this.storageImages[index] = "";
      }
      this.disabledWeightAdjustment =
        this.storageImages.filter((key: any) => key !== "").length < 2;
      if (this.disabledWeightAdjustment) {
        this.lists();
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Gagal menghapus Foto ke storage!")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  attachmentImages = [];
  storageImages = new Array(this.maxPhoto).fill("");

  isErrorMaxChWeightDimension = false;
  isErrorMaxChWeightGoods = false;

  get errorMessageChWeight(): boolean {
    return (
      this.errorMaxChWeight &&
      (this.isErrorMaxChWeightGoods || this.isErrorMaxChWeightDimension)
    );
  }

  onSetDisableDimension(value: string, index: number) {
    const isDisable =
      this.configDisableDimension &&
      Number(value?.replaceAll(",", ".")) < MAX_WEIGHT_SHIPMENT;

    if (this.listKGs[index]) {
      this.listKGs[index].isDimensionDisabled = isDisable;
    }
    if (this.configDisableDimension && +value?.replaceAll(",", ".") < 10) {
      this.listKGs[index].dimension = this.dimensionList(
        this.detailShipment.shipmentGoodDetailKeeped[index]
      );
      this.calculationVolumeWeight(index);
    }
  }

  get errorCHWeight() {
    const limit = Number(
      FlagsMedusa.config_allowance_adjustment_weight_tklp.getValue()
    );
    const CHWeightGap = this.totalChargeableWeight - this.firstCHWeight;
    const CHWeightPromoGap =
      this.totalCampaignChargeableWeight - this.firstCHWeight;
    return {
      status:
        (CHWeightGap > limit || CHWeightPromoGap > limit) &&
        validateCHWeight(this.shipmentList[this.indexElement].shipmentAlgoId),
      msg: `Berat terbaru lebih besar ${limit} Kg dari berat sebelumnya. Mohon cek kembali dan masukkan berat dengan benar`
    };
  }

  get showCodPosFav() {
    return (
      this.isShipmentFavorite && FlagsMedusa.cod_dfod_pos_favorite.isEnabled()
    );
  }

  get deliveryFeeTypes() {
    if (this.shipmentList[this.indexElement].isKeepCod || this.showCodPosFav) {
      return [
        {
          name: "Non COD",
          value: "non-cod",
          disabled: !this.isShipmentFavorite,
          logo: ""
        },
        {
          name: "Cash On Delivery",
          value: "cod",
          disabled: this.codStatusDisabled,
          logo: "cod-logo"
        },
        {
          name: "Delivery Fee on Delivery",
          value: "dfod",
          disabled: this.dfodStatusDisabled,
          logo: "dfod-logo"
        }
      ];
    }

    return [];
  }

  listDataConfigurationCOD: ResponsePayloadV2 = new ResponsePayloadV2();
  listDfodActive = [] as any;
  isEligibleDFOD = false;
  listDfodProductType = [] as any;
  isEligibleCOD = false;
  listCodActive = [] as any;
  listCodProductType = [] as any;

  async fetchListConfigurationCOD() {
    this.listDataConfigurationCOD = await CodConfigurationController.getList(
      new ApiRequestList({
        page: this.listDataConfigurationCOD.pagination.page,
        limit: this.listDataConfigurationCOD.pagination.limit
      })
    );

    // cod
    this.listCodActive = this.listDataConfigurationCOD.data.filter(
      (item: any) => item.ccoName === "COD CA RETAIL"
    );
    this.codStatusShipment();

    // dfod
    this.listDfodActive = this.listDataConfigurationCOD.data.filter(
      (item: any) => item.ccoName === "DFOD CA"
    );
    this.dfodStatusShipment();
  }

  codStatusShipment() {
    if (this.listCodActive[0]?.ccoStatus === "active") {
      this.isEligibleCOD = true;
      this.listCodProductType = this.listCodActive[0].ccoProductType
        .toLowerCase()
        .split(",");
    } else {
      this.isEligibleCOD = false;
    }
  }

  dfodStatusShipment() {
    if (this.listDfodActive[0]?.ccoStatus === "active") {
      this.isEligibleDFOD = true;
      this.listDfodProductType = this.listDfodActive[0].ccoProductType
        .toLowerCase()
        .split(",");
      this.processErrorEstimationPrice(
        String(
          this.shipmentList[this.indexElement].shipmentGoodsPriceEstimation
        )
      );
    } else {
      this.isEligibleDFOD = false;
    }
  }

  codStatusDisabled = true;
  codStatus() {
    const isCodDestination = this.destination?.isCod === "yes";
    let result = false;
    if (this.isEligibleCOD) {
      if (
        !this.listCodProductType.includes(
          this.shipmentList[this.indexElement].shipmentProduct.toLowerCase()
        )
      ) {
        this.shipmentList[this.indexElement].shipmentIsCod = false;
        this.checkIsInsurance();
      }

      result = this.listCodProductType.includes(
        this.shipmentList[this.indexElement].shipmentProduct.toLowerCase()
      );
    } else {
      result = false;
    }

    if (!result || !isCodDestination) {
      this.codStatusDisabled = true;
    } else {
      this.codStatusDisabled = false;
    }

    return result;
  }

  dfodStatusDisabled = true;
  dfodStatus() {
    const isDfodDestination = this.destination?.isCod === "yes";
    let result = false;
    if (this.isEligibleDFOD) {
      if (
        !this.listDfodProductType.includes(
          this.shipmentList[this.indexElement].shipmentProduct.toLowerCase()
        )
      ) {
        this.shipmentList[this.indexElement].shipmentIsDfod = false;
        this.checkIsInsurance();
      }

      result = this.listDfodProductType.includes(
        this.shipmentList[this.indexElement].shipmentProduct.toLowerCase()
      );
    } else {
      result = false;
    }

    if (!result || !isDfodDestination) {
      this.dfodStatusDisabled = true;
    } else {
      this.dfodStatusDisabled = false;
    }

    return result;
  }

  fullFilledCOD(event: any) {
    if (this.codStatus()) {
      this.shipmentList[this.indexElement].codAmount = event;
    }

    this.processErrorEstimationPrice(event);
  }

  errorEstimationPrice = false;
  errMsgPrice = "";
  processErrorEstimationPrice(price: string) {
    const isNonCodValidate =
      this.shipmentList[this.indexElement].codDfodValue === "non-cod";
    const isDfodValidate =
      this.shipmentList[this.indexElement].codDfodValue === "dfod";
    const isCodValidate =
      this.shipmentList[this.indexElement].codDfodValue === "cod";

    const estimation = Number(price?.replace(/\./g, ""));

    if (isNonCodValidate) {
      this.errorEstimationPrice = false;
    } else {
      this.validateMainEstimationPrice(
        estimation,
        isCodValidate,
        isDfodValidate
      );
    }
  }

  validateMainEstimationPrice(
    estimation: number,
    isCodValidate: boolean,
    isDfodValidate: boolean
  ) {
    this.minPriceZero();
    this.maxPriceZero();

    const label = isCodValidate ? "COD" : "DFOD";
    const subLabelMinPrice = isCodValidate
      ? this.listCodActive[0]?.ccoMinPrice
      : this.listDfodActive[0]?.ccoMinPrice;
    const subLabelMaxPrice = isCodValidate
      ? this.listCodActive[0]?.ccoMaxPrice
      : this.listDfodActive[0]?.ccoMaxPrice;
    const validate = {
      codValidate: isCodValidate,
      dfodValidate: isDfodValidate
    };
    const subLabel = {
      minPrice: subLabelMinPrice,
      maxPrice: subLabelMaxPrice
    };

    this.conditionalValidationEstimationPrice(
      estimation,
      validate,
      subLabel,
      label
    );
  }

  minPriceZero() {
    if (
      this.listCodActive[0]?.ccoMinPrice === 0 ||
      this.listDfodActive[0]?.ccoMinPrice === 0
    ) {
      return;
    }
  }

  maxPriceZero() {
    if (
      this.listCodActive[0]?.ccoMaxPrice === 0 ||
      this.listDfodActive[0]?.ccoMaxPrice === 0
    ) {
      return;
    }
  }

  conditionalValidationEstimationPrice(
    estimation: number,
    validate: any,
    subLabel: any,
    label: string
  ) {
    this.validateEstimationPriceIsTrue(estimation, validate, subLabel, label);
    this.validateEstimationPriceIsFalse(estimation, validate);
  }

  validateEstimationPriceIsFalse(estimation: number, validate: any) {
    if (
      this.estimationCompareMinAndMaxPrice(
        estimation,
        this.listCodActive[0]?.ccoMinPrice,
        this.listCodActive[0]?.ccoMaxPrice,
        validate.codValidate
      ) ||
      this.estimationCompareMinAndMaxPrice(
        estimation,
        this.listDfodActive[0]?.ccoMinPrice,
        this.listDfodActive[0]?.ccoMaxPrice,
        validate.dfodValidate
      )
    ) {
      this.errorEstimationPrice = false;
    }
  }

  validateEstimationPriceIsTrue(
    estimation: number,
    validate: any,
    subLabel: any,
    label: string
  ) {
    this.validateEstimationPriceLess(estimation, validate, subLabel, label);
    this.validateEstimationPriceMore(estimation, validate, subLabel, label);
  }

  validateEstimationPriceLess(
    estimation: number,
    validate: any,
    subLabel: any,
    label: string
  ) {
    if (
      this.estimationLessThanPrice(
        estimation,
        this.listCodActive[0]?.ccoMinPrice,
        validate.codValidate
      ) ||
      this.estimationLessThanPrice(
        estimation,
        this.listDfodActive[0]?.ccoMinPrice,
        validate.dfodValidate
      )
    ) {
      this.errMsgPrice = `Minimal Harga Barang ${label} ${formatPriceWithoutCurrency(
        subLabel.minPrice
      )}`;
      this.errorEstimationPrice = true;
    }
  }

  validateEstimationPriceMore(
    estimation: number,
    validate: any,
    subLabel: any,
    label: string
  ) {
    if (
      this.estimationMoreThanPrice(
        estimation,
        this.listCodActive[0]?.ccoMaxPrice,
        validate.codValidate
      ) ||
      this.estimationMoreThanPrice(
        estimation,
        this.listDfodActive[0]?.ccoMaxPrice,
        validate.dfodValidate
      )
    ) {
      this.errMsgPrice = `Maximal Harga Barang ${label} ${formatPriceWithoutCurrency(
        subLabel.maxPrice
      )}`;
      this.errorEstimationPrice = true;
    }
  }

  estimationLessThanPrice(estimasi: number, price: number, validate: boolean) {
    return estimasi < price && validate;
  }

  estimationMoreThanPrice(estimasi: number, price: number, validate: boolean) {
    return estimasi > price && validate;
  }

  estimationCompareMinAndMaxPrice(
    estimasi: number,
    minPrice: number,
    maxPrice: number,
    validate: boolean
  ) {
    return estimasi >= minPrice && estimasi <= maxPrice && validate;
  }

  errorMsgProductDfod =
    "Produk pengiriman tidak diijinkan untuk melakukan DFOD";
  get productErrorDFOD() {
    return (
      this.isEligibleDFOD &&
      this.shipmentList[this.indexElement].codDfodValue === "dfod" &&
      !this.listDfodProductType.includes(
        this.shipmentList[this.indexElement].shipmentProduct.toLowerCase()
      )
    );
  }

  errorMsgDestinationDfod =
    "Alamat tujuan tidak diijinkan untuk melakukan DFOD";
  get destinationErrorDFOD() {
    return (
      this.isEligibleDFOD &&
      this.shipmentList[this.indexElement].codDfodValue === "dfod" &&
      this.destination.isCod === "no"
    );
  }

  get isShipmentFavorite() {
    return SHIPMENT_ID_FAVOURITE.includes(
      this.shipmentList[this.indexElement].shipmentAlgoId.slice(0, 2)
    );
  }

  onChangeDeliveryFeeType(value: string) {
    this.processErrorEstimationPrice(
      String(this.shipmentList[this.indexElement].shipmentGoodsPriceEstimation)
    );
    this.checkIsInsurance();
    this.changeCod(value);
    this.changeDfod(value);
  }

  changeCod(value: string) {
    if (value === "cod") {
      this.shipmentList[this.indexElement].shipmentIsCod = true;
    } else {
      this.shipmentList[this.indexElement].shipmentIsCod = false;
    }
  }

  changeDfod(value: string) {
    if (value === "dfod") {
      this.shipmentList[this.indexElement].shipmentIsDfod = true;
    } else {
      this.shipmentList[this.indexElement].shipmentIsDfod = false;
    }
  }

  disabledInsurance = false;
  disableInsurance() {
    if (this.disableInsuranceCOD || this.disableInsuranceDFOD) {
      this.disabledInsurance = true;
    } else {
      this.disabledInsurance = false;
    }
  }

  get disableInsuranceCOD() {
    return (
      this.listCodActive[0]?.ccoIsInsurance &&
      this.shipmentList[this.indexElement].codDfodValue === "cod"
    );
  }

  get disableInsuranceDFOD() {
    return (
      this.listDfodActive[0]?.ccoIsInsurance &&
      this.shipmentList[this.indexElement].codDfodValue === "dfod"
    );
  }

  checkIsInsurance() {
    if (this.disableInsuranceDFOD || this.disableInsuranceCOD) {
      this.disabledInsurance = true;
      this.shipmentList[this.indexElement].shipmentInsurance = "free";
    } else {
      this.disabledInsurance = false;
    }
  }

  get cityForWoodPacking() {
    return this.origin.cityCode;
  }

  get woodpackingAccess() {
    return FlagsMedusa.permission_woodpacking_shipment.isEnabled();
  }

  async callHeavyWeightSurcharge(id: number) {
    if (
      this.shipmentList[this.indexElement]?.shipmentProduct &&
      this.shipmentList[this.indexElement].shipmentCommodityCode
    ) {
      await HeavyWeightSurchargeController.getDetailMinimumHeavyWeightSurcharge(
        {
          id: id,
          product: this.shipmentList[this.indexElement].shipmentProduct,
          commodity: this.shipmentList[this.indexElement].shipmentCommodityCode
        }
      );
    } else {
      HeavyWeightSurchargeController.setMinimumHeavyWeightSurcharge({
        value: 0,
        existMinimum: false
      });
    }
  }

  async getMinimumHeavyWeight() {
    await this.callHeavyWeightSurcharge(1);
    if (!HeavyWeightSurchargeController.heavyWeightMinimum.minimumExist) {
      await this.callHeavyWeightSurcharge(2);
    }
  }

  get minimumHeavyWeightSurcharge() {
    return HeavyWeightSurchargeController.heavyWeightMinimum;
  }

  async setHwsCommodity() {
    const commodityCode: any = this.shipmentList[this.indexElement]
      .shipmentCommodityName;
    if (typeof commodityCode === "object") {
      this.shipmentList[
        this.indexElement
      ].shipmentCommodityCode = this.payloadSectionTwo.commodityCode;
    } else {
      this.shipmentList[this.indexElement].shipmentCommodityCode = "";
    }

    await this.getMinimumHeavyWeight();
    this.setValidateHWS();
  }

  setValidateHWS() {
    const GW = String(this.listKGs[0].grossWeight).replace(",", ".");
    const grossWeight = +GW;
    this.listKGs[0].warningHwsSingle =
      this.listKGs.length === 1 &&
      this.minimumHeavyWeightSurcharge.minimumExist &&
      grossWeight >= this.minimumHeavyWeightSurcharge.heavyWeightMinimum;

    this.listKGs.forEach((key: any, index: number) => {
      const GW = String(key.grossWeight).replace(",", ".");
      this.listKGs[index].isErrorHws =
        +GW >= this.minimumHeavyWeightSurcharge.heavyWeightMinimum &&
        this.listKGs.length > 1 &&
        this.minimumHeavyWeightSurcharge.minimumExist;
    });
  }

  get disableSubmitForHWS() {
    const hwsError = this.listKGs.filter((item: any) => item.isErrorHws);
    return (
      this.listKGs.length > 1 &&
      this.minimumHeavyWeightSurcharge.minimumExist &&
      hwsError.length > 0
    );
  }

  get disableAddPiecesHws() {
    const hwsError = this.listKGs.filter(
      (item: any) => item.isErrorHws || item.warningHwsSingle
    );
    return hwsError.length > 0;
  }

  isEmbargoCommodity = false;

  checkIsEmbargo(val: any) {
    if (!val) return;
    this.isEmbargoCommodity = false;
    const payload = new CheckEmbargoApiRequest({
      originId: this.origin.cityCode,
      destinationId:
        this.destination.cityCode === undefined
          ? this.shipmentList[this.indexElement].shipmentDestinationCity.code
          : this.destination.cityCode,
      productType: this.shipmentList[this.indexElement]?.shipmentProduct,
      commodityType: val.id.toString(),
      maxWeight: this.shipmentList[this.indexElement].shipmentChargeableWeight
    });

    if (payload.commodityType) {
      EmbargoController.checkIsEmbargo(payload).then(res => {
        this.isEmbargoCommodity = res.isEmbargo;
      });
    }
  }
}
